import React, { useEffect, useState } from 'react';
import styles from './QueueHeader.module.scss';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { HeaderCalendarIcon } from '../../../assets/svgComponents/HeaderCalendarIcon';
import { HeaderClockIcon } from '../../../assets/svgComponents/HeaderClockIcon';
import { HeaderLogoIcon } from '../../../assets/svgComponents/HeaderLogoIcon';

export const QueueHeader = () => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<string>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM', { locale: ru }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.logoWrapper}>
        <HeaderLogoIcon />
      </div>

      <div className={styles.dateTimeContainer}>
        <div className={styles.timeSectionComponent}>
          <HeaderCalendarIcon />
          <div className={styles.date}>
            <span>{date}</span>
          </div>
        </div>

        <div className={styles.timeSectionComponent}>
          <HeaderClockIcon />
          <div className={styles.time}>
            <span>{time}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default QueueHeader;
