import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

type ActivateDeactivateDataType = {
  state: string;
};
export const useActivateDeactivateCategory = (closeMenu: (id: string | null) => void) => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const {
    mutate: activateOrDeactivateCategory,
    isLoading: isLoadingCategoryActivatingDeactivating
  } = useMutation(
    (requestData: { categoryId: number; data: ActivateDeactivateDataType }) => {
      const { categoryId, data } = requestData;
      return axios.put(
        CategoriesTopicsTableAPI.activateDeactivateCategory(categoryId),
        data,
        commonHeaders()
      );
    },
    {
      onSuccess() {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeMenu(null))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return {
    activateOrDeactivateCategory,
    isLoadingCategoryActivatingDeactivating
  };
};
