import { useQuery } from 'react-query';
import axios from 'axios';
import { AgentAPI } from '../../constants/API';
import { commonHeaders } from '../../constants/HeadersAPI';

type ResponseType = {
  data: TicketType;
};

export type TicketType = {
  comments: string[] | [];
  createdAt: string;
  itemId: number;
  name: string;
  requestedAt: string;
  status: string;
  topicName: string;
};

export const useGetUsedTicket = (
  isWorkPlaceSelected: boolean,
  setRefreshTicket: (ticket: TicketType | null) => void
) => {
  const {
    data: usedTicket,
    isFetching: isLoadingUsedTicket,
    isError: isErrorTicket,
    isSuccess: isSuccessUsedTicket
  } = useQuery(
    ['usedTicket'],
    () => axios.get<ResponseType>(`${AgentAPI.changeTicketStatus}/refresh`, commonHeaders()),
    {
      onSuccess: (data: ResponseType) => {
        setRefreshTicket(data.data);
      },
      enabled: isWorkPlaceSelected
    }
  );
  return {
    usedTicket,
    isLoadingUsedTicket,
    isErrorTicket,
    isSuccessUsedTicket
  };
};
