import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './ModalWorkplace.module.scss';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { SearchTextField } from '../../SearchTextField/SearchTextField';
import { crossIcon } from '../../../../assets/icons';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { workingPlaceType } from '../../Tables/WorkingPlacesTable/WorkingPlacesTable';
import { DropDown } from '../../DropDown/DropDown';
import {
  UsersRolesType,
  useGetAllRoles
} from '../../../../hooks/Admin/WorkingPlacesTable/useGetRolesAndEmployees';
import {
  AssignedEmployeeType,
  WorkPlaceType
} from '../../../../pagesAdmin/WorkplacesPage/WorkplacesPage';
import { useEditWorkingPlace } from '../../../../hooks/Admin/WorkingPlacesTable/useEditWorkingPlace';
import { useCreateWorkingPlace } from '../../../../hooks/Admin/WorkingPlacesTable/useCreateWorkingPlace';
import { Button } from '../../Button/Button';
import { ModalWorkpaceSchema } from '../../../../schemas/ModalWorkpaceSchema';

type ModalWorkplaceProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  data?: workingPlaceType;
  setActiveRowData: (value: null | workingPlaceType) => void;
  setAgentsRequestData: (requestBody: WorkPlaceType | null) => void;
  setModalAccessLevelsActive: (modalStatus: boolean) => void;
  agentsRequestData: WorkPlaceType | null;
  backStage: boolean;
  setBackStage: (status: boolean) => void;
  isCreateMode?: boolean;
  setIsCreateMode: (status: boolean) => void;
};

export const ModalWorkplace: FC<ModalWorkplaceProps> = ({
  isActive,
  setActive,
  data,
  setActiveRowData,
  setModalAccessLevelsActive,
  setAgentsRequestData,
  setBackStage,
  isCreateMode,
  setIsCreateMode,
  agentsRequestData,
  backStage
}) => {
  const [filteredItems, setFilteredItems] = useState<AssignedEmployeeType[]>([]);
  const [addedEmployees, setAddedEmployees] = useState<AssignedEmployeeType[]>([]);
  const [uniqRoles, setUniqRoles] = useState<UsersRolesType[]>([]);
  const { rolesResp } = useGetAllRoles();
  const [searchText, setSearchText] = useState<string>('');
  const { editWorkingPlace, isLoadingEditWorkingPlace } = useEditWorkingPlace(
    setActive,
    setAgentsRequestData
  );
  const { createWorkingPlace, isLoadingCreateWorkingPlace } = useCreateWorkingPlace(
    setActive,
    setAgentsRequestData
  );

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropDownItem, setDropDownItem] = useState(null);

  const modalRef = useRef<HTMLDivElement>(null);
  type FormData = yup.InferType<typeof ModalWorkpaceSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(ModalWorkpaceSchema),
    mode: 'onChange',
    defaultValues: {
      workpaceName: '',
      boardNumber: '',
      officeNumber: '',
      type: '',
      digitalDisplayIp: '',
      searchInputText: ''
    }
  });

  useEffect(() => {
    if (rolesResp) {
      const uniqRolesArr: UsersRolesType[] = rolesResp.reduce(
        (acc, item) => (acc.find((e) => e.role === item.role) ? acc : [...acc, item]),
        [] as UsersRolesType[]
      );
      setUniqRoles(uniqRolesArr);
    }
  }, [rolesResp]);

  useEffect(() => {
    if (rolesResp) {
      const currentUsers = rolesResp
        .filter((user) => user.role === dropDownItem)
        .filter((user) => !addedEmployees?.map((item) => item.id).includes(user.id));
      setFilteredItems(currentUsers);
    }
  }, [dropDownItem, rolesResp]);

  useEffect(() => {
    if (!isActive) {
      reset();
      setFilteredItems([]);
      setAddedEmployees([]);
      setDropDownItem(null);
    }
  }, [isActive]);

  useEffect(() => {
    if (isCreateMode) {
      setValue('workpaceName', '');
      setValue('boardNumber', '');
      setValue('digitalDisplayIp', '');
      setValue('officeNumber', '');
      setAddedEmployees([]);
      setDropDownItem(null);
      setValue('type', '');
    }
    if (backStage) {
      agentsRequestData.description && setValue('workpaceName', agentsRequestData.description);
      agentsRequestData.windowNumber &&
        setValue('boardNumber', agentsRequestData.windowNumber.toString());
      agentsRequestData.digitalDisplayIp &&
        setValue('digitalDisplayIp', agentsRequestData.digitalDisplayIp);
      agentsRequestData.officeNumber &&
        setValue('officeNumber', String(agentsRequestData.officeNumber));
      setAddedEmployees(
        agentsRequestData.assignedEmployees ? agentsRequestData.assignedEmployees : []
      );
      setDropDownItem(agentsRequestData.type);
      setValue('type', agentsRequestData.type);
    }
    if (data && !backStage) {
      data.workingPlace && setValue('workpaceName', data.workingPlace);
      data.number && setValue('boardNumber', data.number.toString());
      data.boardIp && setValue('digitalDisplayIp', data.boardIp);
      data.cabinet && setValue('officeNumber', String(data.cabinet));
      setAddedEmployees(data.assignedEmployees ? data.assignedEmployees : []);
      setDropDownItem(data.type);
      setValue('type', data.type);
    }
  }, [data, agentsRequestData, isCreateMode]);

  const addEmloyeeToArr = (item: AssignedEmployeeType) => {
    if (!addedEmployees?.some((service) => service.id === item.id)) {
      setAddedEmployees((prevState) => [...prevState, item]);
    }
  };

  const addRemovedEmployee = (item) => {
    setFilteredItems((prevState) => [...prevState, item]);
  };

  const removeEmplyeeById = (id: number): void => {
    setAddedEmployees((prevState) =>
      prevState.filter((employee: AssignedEmployeeType) => employee.id !== id)
    );
  };

  const removeSelectedUser = (user) => {
    setFilteredItems((prevState) => prevState.filter((item) => item.id !== user.id));
  };

  const onSubmit: SubmitHandler<FormData> = (body: FormData) => {
    const { workpaceName, boardNumber, digitalDisplayIp, officeNumber } = body;
    const requestBody = {
      description: workpaceName,
      windowNumber: Number(boardNumber) || null,
      officeNumber: Number(officeNumber) || null,
      type: dropDownItem,
      assignedEmployees: addedEmployees,
      assignedEmployeesIds: addedEmployees?.map((item) => item.id),
      digitalDisplayIp
    };
    const requestOperatorsBody = {
      description: workpaceName,
      windowNumber: Number(boardNumber) || null,
      officeNumber: Number(officeNumber) || null,
      type: dropDownItem,
      users: addedEmployees?.map((item) => item.id),
      digitalDisplayIp
    };
    if (data && (dropDownItem === 'Оператор на въезд' || dropDownItem === 'Оператор на выезд')) {
      editWorkingPlace({ requestBody: requestOperatorsBody, id: data.id });
      setBackStage(false);
    } else if (
      !data &&
      (dropDownItem === 'Оператор на въезд' || dropDownItem === 'Оператор на выезд')
    ) {
      createWorkingPlace(requestOperatorsBody);
      setBackStage(false);
    }
    if (data && dropDownItem === 'Оператор') {
      setAgentsRequestData({ ...requestBody, id: data.id });
      setActive(false);
      setModalAccessLevelsActive(true);
      setBackStage(true);
    } else if (!data && dropDownItem === 'Оператор') {
      setAgentsRequestData(requestBody);
      setActive(false);
      setModalAccessLevelsActive(true);
      setBackStage(true);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActive(false);
      setActiveRowData(null);
      setIsCreateMode(false);
      setBackStage(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div className={classes.modalContainer} ref={modalRef}>
        <form className={classes.workplaceForm}>
          {(isLoadingEditWorkingPlace || isLoadingCreateWorkingPlace) && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>{isCreateMode ? 'Создание рабочего места' : 'Редактирование рабочего места'}</h2>
          <div className={classes.workpaceName}>
            <CustomTextField
              {...register('workpaceName')}
              name="workpaceName"
              value={watch('workpaceName')}
              labelName={'Наименование рабочего места'}
              errorMessage={errors.workpaceName?.message}
            />
          </div>
          <div className={classes.numberContainer}>
            <div
              className={classNames(classes.accessLevel, {
                [classes.accessLevelWarning]: errors.type
              })}>
              <DropDown
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                label={'Уровень доступа'}
                activeService={watch('type')}>
                {uniqRoles &&
                  uniqRoles.map((item: { id: number; name: string; role: string }) => (
                    <li
                      key={item.id}
                      className={classes.serviceItem}
                      onClick={(): void => {
                        setDropDownItem(item.role);
                        setAddedEmployees([]);
                        setMenuOpen(false);
                        setValue('type', item.role);
                      }}>
                      <span>{item.role}</span>
                    </li>
                  ))}
              </DropDown>
            </div>
            {dropDownItem === 'Оператор' && (
              <div className={classes.officeNumber}>
                <CustomTextField
                  {...register('officeNumber')}
                  name="officeNumber"
                  value={watch('officeNumber')}
                  labelName={'Номер кабинета '}
                  errorMessage={errors.officeNumber?.message}
                />
              </div>
            )}

            {dropDownItem === 'Оператор' && (
              <div className={classes.windowNumber}>
                <CustomTextField
                  {...register('boardNumber')}
                  name="boardNumber"
                  value={watch('boardNumber')}
                  labelName={'Номер окна'}
                  errorMessage={
                    errors.officeNumber?.type === 'required' ? ' ' : errors.boardNumber?.message
                  }
                />
              </div>
            )}
            {dropDownItem === 'Оператор' && (
              <div className={classes.windowIP}>
                <CustomTextField
                  {...register('digitalDisplayIp')}
                  name="digitalDisplayIp"
                  value={watch('digitalDisplayIp')}
                  labelName={'IP табло'}
                  errorMessage={errors.digitalDisplayIp?.message}
                />
              </div>
            )}
          </div>
          <div className={classes.addEmployeesContainer}>
            <div className={classes.addEmployees}>
              <SearchTextField
                {...register('searchInputText')}
                name="searchInputText"
                labelName={'Добавить сотрудников'}
                placeholder={'Введите название'}
                clearInput={(): void => {
                  setValue('searchInputText', '');
                  setSearchText('');
                }}
                onChange={(e) => setSearchText(e.target.value)}>
                {filteredItems
                  ?.filter((e) => e.name.toLowerCase().includes(searchText.toLowerCase()))
                  .map((item) => (
                    <div
                      key={item.id}
                      className={classes.userList}
                      onClick={() => {
                        addEmloyeeToArr(item);
                        removeSelectedUser(item);
                      }}>
                      {item.name}
                    </div>
                  ))}
              </SearchTextField>
            </div>
            <div className={classes.removeEmployees}>
              <CustomTextField labelName={'Добавленные сотрудники'} disabled={true}>
                {addedEmployees?.map((item) => (
                  <div
                    key={item.id}
                    className={classes.addedServiceItem}
                    onClick={() => {
                      removeEmplyeeById(item.id);
                      addRemovedEmployee(item);
                    }}>
                    <div className={classes.crossContainer}>
                      <img src={crossIcon} alt={'cross'} />
                    </div>
                    {item.name}
                  </div>
                ))}
              </CustomTextField>
            </div>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              btnStyle={'white'}
              content="отмена"
              onClick={() => {
                setActive(false);
                setActiveRowData(null);
                setIsCreateMode(false);
                setBackStage(false);
                setAgentsRequestData(null);
              }}
            />
            <Button
              content={dropDownItem === 'Оператор' ? 'далее' : 'сохранить'}
              btnStyle={'blue'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoadingEditWorkingPlace || isLoadingCreateWorkingPlace}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
