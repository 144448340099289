import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';
import { queueScreenHeaders } from '../../constants/HeadersAPI';
import { baseSoundURL } from '../../config';

export type QueueVideoPlaylistRespType = {
  video: VideoType[];
};

type VideoType = {
  fileId: number;
  order: number;
  filePath: string;
};

export const useGetQueueVideoPlaylist = (setVideoPlaylist: (playlist: string[]) => void) => {
  const {
    data: queueVideoPlaylistResp,
    isFetching: isLoadingQueueVideoPlaylist,
    isSuccess: isQueueVideoPlaylistRecieved
  } = useQuery(
    'QueueVideoPlaylist',
    () =>
      axios.get<QueueVideoPlaylistRespType>(
        MonitorQueueAPI.getQueueVideoPlaylist,
        queueScreenHeaders()
      ),
    {
      onSuccess: (resp) =>
        setVideoPlaylist(resp.data.video.map((e) => `${baseSoundURL}${e.filePath}`)),
      refetchInterval: 30000
    }
  );

  return { queueVideoPlaylistResp, isLoadingQueueVideoPlaylist, isQueueVideoPlaylistRecieved };
};
