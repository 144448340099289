import React, { FC } from 'react';
import classes from './IconsStyles.module.scss';

export const FooterAsterixIcon: FC = () => {
  return (
    <>
      <svg
        className={classes.footerAsterixIcon}
        viewBox="0 0 82 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M41 100.824C39.27 100.824 37.7131 99.4192 37.5401 97.6626C34.4261 65.6939 32.0042 55.5061 3.11393 52.6956C1.38397 52.52 0 50.9391 0 49.1826C0 47.4261 1.38397 45.8452 3.11393 45.6696C32.6962 42.8591 34.4261 35.1304 37.5401 3.16174C37.5401 1.40522 39.27 0 41 0C42.7299 0 44.2869 1.40522 44.4599 3.16174C47.5738 35.1304 49.4768 42.8591 78.8861 45.6696C80.616 45.8452 82 47.4261 82 49.1826C82 50.9391 80.616 52.52 78.8861 52.6956C49.8228 55.5061 47.5738 65.6939 44.4599 97.8383C44.4599 99.5948 42.9029 101 41 101V100.824Z"
          fill="#0066FF"
        />
      </svg>
    </>
  );
};
