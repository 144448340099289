import classes from './Header.module.scss';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { adminBlack } from '../../../assets/icons';
import InvalidLicenseMessage from './LicenseStatus/InvalidLicenseMessage';
import ModalLogout from '../../Main/Modals/ModalLogout/ModalLogout';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

type HeaderProps = {
  licenseDaysLeft: number;
  setIsAuthenticated: (status: boolean) => void;
};

export const Header: FC<HeaderProps> = ({ licenseDaysLeft, setIsAuthenticated }) => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState('');
  const [activeLogout, setActiveLogout] = useState(false);
  const headerStyle =
    licenseDaysLeft >= 91 ? classes.headerValidLicense : classes.headerInvalidLicense;
  const [user, setUser] = useState<string>('');
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const parsedUserData = JSON.parse(userData).fullName;
      setUser(parsedUserData);
    }
  }, []);
  const handleClick = () => {
    setActiveLogout(true);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'dd MMMM yyyy', { locale: ru }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={headerStyle}>
      <div className={classes.datetime}>
        <div className={classes.time}>
          <span>{time}</span>
        </div>
        <div className={classes.date}>
          <span>{date}</span>
        </div>
      </div>
      {licenseDaysLeft <= 90 && <InvalidLicenseMessage daysLeft={licenseDaysLeft} />}
      <div className={classes.admin} onClick={() => handleClick()}>
        <div>
          <span>{user}</span>
        </div>
        <div className={classes.adminLogo}>
          <img src={adminBlack} alt={'adminLogo'} />
        </div>
      </div>
      <ModalLogout
        setIsAuthenticated={setIsAuthenticated}
        activeLogout={activeLogout}
        setActiveLogout={setActiveLogout}
      />
    </div>
  );
};
