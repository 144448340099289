import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AgentAPI } from '../../constants/API';

export const useGetTicketComments = (ticketId: number) => {
  const {
    data: ticketCommentsResp,
    isFetching: isLoadingTicketComments,
    isError: isErrorTicketComments,
    isSuccess: isSuccessTicketComments
  } = useQuery(
    ['ticketComments', ticketId],
    () => axios.get(`${AgentAPI.changeTicketStatus}/${ticketId}/comments`, commonHeaders()),
    {
      enabled: !!ticketId
    }
  );
  return {
    ticketCommentsResp,
    isLoadingTicketComments,
    isErrorTicketComments,
    isSuccessTicketComments
  };
};
