import * as yup from 'yup';

const AuthorizationSchema = yup
  .object({
    username: yup.string().required('Заполните поле "ID пользователя"'),
    password: yup.string().required('Заполните поле "пароль"')
  })
  .required();

export default AuthorizationSchema;
