import { NavigateFunction } from 'react-router';
import { rolesURLs } from '../../constants/rolesURLs';

type UserDataType = {
  fullName: string;
  id: number;
  login: string;
  role: string;
  token: string;
};

export const setUserRole = (userData: UserDataType, nav: NavigateFunction) => {
  localStorage.setItem('user', JSON.stringify(userData));
  const currentRole = rolesURLs.filter((e) => e.role === userData.role)[0];
  nav(currentRole.path);
};

export const getUserData = (): UserDataType | string => {
  return localStorage.getItem('user') === null ? '' : JSON.parse(localStorage.getItem('user'));
};

export const isUserRole = () => {
  return localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')).role : '';
};

export const getUserPath = () => {
  const currentRoleData = rolesURLs.filter((e) => e.role === isUserRole());
  return currentRoleData[0].path;
};
