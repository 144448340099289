import { useMutation } from 'react-query';
import axios from 'axios';
import { WorkingPlacesTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { WorkingPlacesRequestBodyType } from '../../../types/Admin/WorkingPlacesTypes';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';
import { WorkPlaceType } from '../../../pagesAdmin/WorkplacesPage/WorkplacesPage';

export const useEditWorkingPlace = (
  setActiveModal: (value: boolean) => void,
  setAgentsRequestData: (requestBody: WorkPlaceType | null) => void
) => {
  const context = useContext(AlertContext);
  const {
    mutate: editWorkingPlace,
    isLoading: isLoadingEditWorkingPlace,
    isError
  } = useMutation(
    ['updateParkingPlaces'],
    (data: WorkingPlacesRequestBodyType) =>
      axios.put(
        WorkingPlacesTableAPI.putUpdateWorkingPlace(data.id),
        data.requestBody,
        commonHeaders()
      ),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('workingPlaces')
          .then(() => setActiveModal(false))
          .then(() => setAgentsRequestData(null))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { editWorkingPlace, isLoadingEditWorkingPlace, isError };
};
