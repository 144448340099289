import classNames from 'classnames';
import React, { useEffect } from 'react';
import classes from './ModalTickerBoard.module.scss';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { Button } from '../../../Button/Button';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTickerSchema } from '../../../../../schemas/InputTextSchema';
import { TickersDataTableType } from '../../../Tables/AdvertisingTable/RunningQweryTable/TickersTable';
import { ModalMode } from '../../../../../types/enums';
import { CreateNewTickerType } from '../../../../../hooks/Admin/Advertising/usePostTicker';
import { EditTickerType } from '../../../../../hooks/Admin/Advertising/usePutTicker';

type ModalTickerBoardProps = {
  editTickerModalActive: boolean;
  setEditTickerModalActive: (status: boolean) => void;
  createNewTicker: (data: CreateNewTickerType) => void;
  editTicker: (data: EditTickerType) => void;
  isLoading: boolean;
  mode: string;
  data?: TickersDataTableType;
};
export const ModalTickerBoard = ({
  mode,
  editTickerModalActive,
  isLoading,
  createNewTicker,
  editTicker,
  setEditTickerModalActive,
  data
}: ModalTickerBoardProps) => {
  type FormData = yup.InferType<typeof InputTickerSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(InputTickerSchema),
    mode: 'onChange',
    defaultValues: {
      inputText: ''
    }
  });

  useEffect(() => {
    if (mode === ModalMode.Edit && data && data.text) {
      setValue('inputText', data.text);
    }
    if (mode === ModalMode.Create) {
      setValue('inputText', '');
    }
  }, [data]);

  const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
    const requestBody = { text: formData.inputText };

    if (mode === ModalMode.Create) {
      createNewTicker(requestBody);
    }
    if (mode === ModalMode.Edit) {
      editTicker({ tickerId: data?.id, body: requestBody });
    }
  };
  return (
    <div
      className={classNames(classes.modal, { [classes.active]: editTickerModalActive })}
      onClick={() => setEditTickerModalActive(false)}>
      {isLoading && (
        <div className={classes.load}>
          <div className={classes.loadContent}>
            <p>Cохранение...</p>
          </div>
        </div>
      )}
      <div
        className={classes.modalContainer}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <form className={classes.formText}>
          <h2>
            {mode === ModalMode.Edit ? 'Изменение бегущей строки' : 'Создание бегущей строки'}
          </h2>
          <div className={classes.textInput}>
            <CustomTextField
              {...register('inputText')}
              isTextarea={true}
              name="inputText"
              value={watch('inputText')}
              labelName={'Текст бегущей строки'}
              errorMessage={errors.inputText?.message}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              content={'отмена'}
              btnStyle={'white'}
              onClick={() => setEditTickerModalActive(false)}
            />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
