import { FC, useCallback, useEffect } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AgentAPI } from '../../../constants/API';
import axios from 'axios';

type AgentsQueueNotificationProps = {
  isAgentWorkInQueue: boolean;
  setIsAgentWorkInQueue: (status: boolean) => void;
};

const AgentsQueueNotification: FC<AgentsQueueNotificationProps> = ({
  isAgentWorkInQueue,
  setIsAgentWorkInQueue
}) => {
  const sendNotification = () => {
    if ('Notification' in window && Notification.permission === 'granted') {
      new Notification('Клиент ожидает в очереди', {
        body: 'Пожалуйста, обработайте запрос клиента'
      });
    }
  };

  const requestNotificationPermission = useCallback(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notifications enabled');
        }
      });
    }
  }, []);

  useEffect(() => {
    if ('Notification' in window) {
      requestNotificationPermission();
    }
  }, [requestNotificationPermission]);

  useEffect(() => {
    if (!isAgentWorkInQueue) {
      const fetchData = async () => {
        try {
          const response = await axios.get(AgentAPI.getAgentQueue, commonHeaders());
          if (response.data.activeItems.length) {
            sendNotification();
          }
        } catch (error) {
          console.error(error);
        }
      };

      const intervalId = setInterval(fetchData, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isAgentWorkInQueue]);

  const checkVisibility = () => {
    if (document.visibilityState === 'visible') {
      setIsAgentWorkInQueue(true);
    } else {
      setIsAgentWorkInQueue(false);
    }
  };

  document.addEventListener('visibilitychange', checkVisibility);

  return null;
};

export default AgentsQueueNotification;
