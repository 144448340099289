// const baseURL = 'http://localhost:8080/api';
// export const baseSoundURL = 'http://localhost:8080';
import { baseURL } from '../config';

//todo add terminalToken in Enum

// Notifying operators that a client is waiting in a queue
export const isNotificationEnabled = true;

// Queue Screen Options. Choose only one of the 2 follow options
export const isQueueTitleEnabled = true;
export const isQueueWeatherForecastEnabled = false;

export const QueueTitle = 'Белинтертранс';
export const QueueSubTitle = 'Международные перевозки грузов';

export const isQueueScreenVideoAutoplayEnabled = true;
export const videoVolume = 0.1; // from 0 to 1

// Terminal Screen Options.
export const isAfterPrintTicketEnabled = false;

export enum StaticTokens {
  monitorToken = '47e662c1-62ce-4a82-9fdd-9f773f0b63e1',
  terminalToken = '239c8f78-5083-4ff5-bd3e-cc7fa701606f'
}

export const AuthorizationAPI = {
  logIn: `${baseURL}/auth/login`,
  logOut: `${baseURL}/auth/logout`,
  getUserWorkplaces: `${baseURL}/auth/user-work-places`,
  putUserAuthInfo: `${baseURL}/auth/user-auth-info`
};

export const OperatorsAPI = {
  getQueueIn: `${baseURL}/service-units/view`,
  getCarData: (itemId: number) => `${baseURL}/items/${itemId}`,
  getContainersData: (value?: string) => `${baseURL}/containers?name=${value}`,
  getClientsData: (value?: string) => `${baseURL}/clients?name=${value}`,
  updateCarStatus: (id?: number) => `${baseURL}/items/${id}`,
  redirectCarInQueueOut: (id?: number) => `${baseURL}/items/${id}/redirect`,
  addCarInQueue: () => `${baseURL}/items`,
  printEntryTicket: (entryTicketId: number) => `${baseURL}/entry-ticket/${entryTicketId}/print`,
  refreshTicket: () => `${baseURL}/items/refresh`,
  synchronizeDataBase: () => `${baseURL}/synchronization-info`
};

export const AgentAPI = {
  getAgentQueue: `${baseURL}/service-units/view`,
  changeTicketStatus: `${baseURL}/items`
};
export const TerminalServicesAPI = {
  getServices: `${baseURL}/order-units/view`,
  getTicketInTerminal: `${baseURL}/items`
};

export const MonitorQueueAPI = {
  getQueueItems: `${baseURL}/monitor-units/view`,
  getQueueTickers: `${baseURL}/monitor-units/tickers`,
  getQueueHeaders: `${baseURL}/monitor-units/texts`,
  getQueueVideoPlaylist: `${baseURL}/monitor-units/playlists`,
  putSoundStatus: (itemId: number) =>
    `${baseURL}/audios/${itemId}?token=${StaticTokens.monitorToken}`
};

export const CategoriesTopicsAPI = {
  getCategoriesTopics: `${baseURL}/service-units/operators-topics`
};

export const RedirectServiceAPI = {
  postRedirectService: (itemId: number) => `${baseURL}/items/${itemId}/redirect`
};

export const CategoriesTopicsTableAPI = {
  postCreateCategory: `${baseURL}/categories`,
  getAllCategoriesTopics: `${baseURL}/categories-topics/view`,
  getCategoryInfoById: (categoryId: number) => `${baseURL}/categories/${categoryId}`,
  getCategoriesAndTopicsForModal: `${baseURL}/categories-topics`,
  postEditCategory: (categoryId: number) => `${baseURL}/categories/${categoryId}`,
  deleteCategory: (categoryId: number) => `${baseURL}/categories/${categoryId}`,
  activateDeactivateCategory: (categoryId: number) =>
    `${baseURL}/categories/${categoryId}/activate`,
  postCreateTopic: `${baseURL}/topics`,
  postEditTopic: (topicId: number) => `${baseURL}/topics/${topicId}`,
  deleteTopic: (topicId: number) => `${baseURL}/topics/${topicId}`,
  activateDeactivateTopic: (topicId: number) => `${baseURL}/topics/${topicId}/activate`
};

export const AdminAPI = {
  getAllCategoriesTopics: `${baseURL}/categories-topics/view`,
  getAllParkingPlaces: `${baseURL}/parking-spaces`,
  putUpdateParkingPlaces: (parkingSpaceId: number) => `${baseURL}/parking-spaces/${parkingSpaceId}`,
  getAllEmployees: `${baseURL}/users/view`,
  getAllSystemTopics: `${baseURL}/topics`,
  postNewUser: `${baseURL}/users`,
  putUserStatus: (userId: number) => `${baseURL}/users/${userId}/activate`,
  deleteUser: (userId: number) => `${baseURL}/users/${userId}`,
  putUserTopic: (roleId: number) => `${baseURL}/users/roles/${roleId}`,
  putUserData: (userId: number) => `${baseURL}/users/${userId}`
};

export const WorkingPlacesTableAPI = {
  getAllWorkingPlaces: `${baseURL}/service-units/work-places`,
  getRolesAndEmplouees: `${baseURL}/users/work-place-info`,
  postCreateWorkingPlace: `${baseURL}/service-units`,
  putUpdateWorkingPlace: (workingPlaceId: number) => `${baseURL}/service-units/${workingPlaceId}`,
  deleteWorkingPlace: (workingPlaceId: number) => `${baseURL}/service-units/${workingPlaceId}`,
  putWorkingPlaceStatus: (workingPlaceId: number) =>
    `${baseURL}/service-units/${workingPlaceId}/activate`
};

export const AdminAnalyticsAndReportsAPI = {
  getCurrentServicesStatusEmployees: `${baseURL}/reports/client-service-employee`,
  getCurrentServicesStatusQueue: `${baseURL}/reports/client-service-queue`,
  getStationReportToday: `${baseURL}/reports/station-today`,
  getStationReportPeriod: `${baseURL}/reports/station-period`,
  getClientReportToday: `${baseURL}/reports/client-today`,
  getClientReportPeriod: `${baseURL}/reports/client-period`,
  getContainersReport: `${baseURL}/reports/container`,
  getServicesReport: `${baseURL}/reports/service`,
  getEmployeesReport: `${baseURL}/reports/worker`,
  getCarNumbersReport: `${baseURL}/reports/car-number`,
  getVisitorsReport: `${baseURL}/reports/client`
};

export const AdvertisingAPI = {
  getAllSettings: `${baseURL}/content/view`,
  getAllVideos: `${baseURL}/content/video`,
  getAllTickers: `${baseURL}/content/tickers`,
  postVideo: `${baseURL}/content/video/upload`,
  postTicker: `${baseURL}/content/tickers`,
  putTicker: (tickerId: number) => `${baseURL}/content/tickers/${tickerId}`,
  putHeaders: `${baseURL}/content/texts`,
  putPlaylist: `${baseURL}/content/playlists`,
  deleteTicker: (tickerId: number) => `${baseURL}/content/tickers/${tickerId}`,
  deleteVideo: (videoId: number) => `${baseURL}/content/video/${videoId}`,
  activateContent: (contentId: number) => `${baseURL}/content/${contentId}/activate`
};
