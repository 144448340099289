import React, { FC } from 'react';
import classes from './ActionMenu.module.scss';

type ActionMenuProps = {
  isActivate?: boolean;
  editOnClick?: () => void;
  deleteOnClick?: () => void;
  activateDeactivateClick?: () => void;
};
export const ActionMenu: FC<ActionMenuProps> = ({
  isActivate,
  editOnClick,
  deleteOnClick,
  activateDeactivateClick
}) => {
  return (
    <ul className={classes.menuContainer}>
      {editOnClick && <li onClick={() => editOnClick()}>{'Редактировать'}</li>}
      {activateDeactivateClick && (
        <li onClick={() => activateDeactivateClick()}>
          {isActivate ? 'Деактивировать' : 'Активировать'}
        </li>
      )}
      {deleteOnClick && <li onClick={() => deleteOnClick()}>{'Удалить'}</li>}
    </ul>
  );
};
