import { useMutation } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { queryClient } from '../../../queryClient';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type PlaylistsType = {
  videos: PlaylistType[];
};

type PlaylistType = {
  order: number;
  videoId: number;
};

export const usePutPlaylist = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: editPlaylist,
    isLoading: isEditPlaylistLoading,
    isError
  } = useMutation(
    ['editPlaylist'],
    (data: PlaylistsType) => axios.put(AdvertisingAPI.putPlaylist, data, commonHeaders()),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('AdvertisingSettings')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { editPlaylist, isEditPlaylistLoading, isError };
};
