import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type EmployeesReportType = {
  worker: string;
  clientsCalledFromQueue: string;
  clientsDone: string;
  clientsRedirect: string;
  clientsNotCome: string;
  avgServiceTime: string;
  avgDownTime: string;
};

export const useGetEmployeesReport = (date: AnalyticsAndReportsDateType) => {
  const {
    data: employeesReportResp,
    isFetching: isLoadingEmployeesReport,
    isSuccess: isEmployeesReportRecieved,
    refetch: refetchEmployeesReport
  } = useQuery(
    ['employeesReport'],
    () =>
      axios.get<EmployeesReportType[]>(
        `${AdminAnalyticsAndReportsAPI.getEmployeesReport}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              employer: e.worker,
              calledFromQueue: e.clientsCalledFromQueue,
              served: e.clientsDone,
              redirected: e.clientsRedirect,
              missed: e.clientsNotCome,
              averageServiceTime: e.avgServiceTime,
              averageUnActiveTime: e.avgDownTime
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    employeesReportResp,
    isLoadingEmployeesReport,
    isEmployeesReportRecieved,
    refetchEmployeesReport
  };
};
