import { useQuery } from 'react-query';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../../constants/HeadersAPI';
import {
  CategoriesAndTopicsForModal,
  categoryServiceType
} from '../../../types/Admin/CategoriesTopicsTypes';

export const useGetCategoriesTopicsForModal = (
  topLevel: { id: null; nameRu: string },
  setParentCategoryItems: (items: categoryServiceType[]) => void
) => {
  const {
    data: categoriesAndTopics,
    isLoading: isLoadingAllCategoriesAndTopics,
    isSuccess: isAllCategoriesAndTopicsReceived
  } = useQuery(
    ['categoriesAndTopicsForModal'],
    () =>
      axios.get<CategoriesAndTopicsForModal>(
        CategoriesTopicsTableAPI.getCategoriesAndTopicsForModal,
        commonHeaders()
      ),
    {
      select: ({ data }) => data,
      onSuccess: (data: CategoriesAndTopicsForModal) => {
        if (data?.categories) {
          setParentCategoryItems([topLevel, ...data.categories]);
        } else {
          setParentCategoryItems([topLevel]);
        }
      }
    }
  );
  return {
    categoriesAndTopics,
    isLoadingAllCategoriesAndTopics,
    isAllCategoriesAndTopicsReceived
  };
};
