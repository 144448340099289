import classes from './Button.module.scss';
import * as React from 'react';
import classNames from 'classnames';
import { LoadingSpinnerBtn } from '../Spinner/LoadingSpinnerBtn';

export type ButtonProps = {
  content: string;
  btnStyle: 'blue' | 'white';
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isDeleteBtn?: boolean;
};
export const Button = ({
  content,
  btnStyle,
  onClick,
  isLoading,
  isDisabled,
  isDeleteBtn
}: ButtonProps) => {
  return (
    <button
      type="button"
      disabled={isLoading || isDisabled}
      className={classNames(classes.normal, {
        [classes.active]: btnStyle === 'blue',
        [classes.isDisabled]: isDisabled,
        [classes.delete]: isDeleteBtn
      })}
      onClick={onClick}>
      {!isLoading && content}
      {isLoading && <LoadingSpinnerBtn />}
    </button>
  );
};
