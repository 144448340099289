import { useMutation } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { queryClient } from '../../../queryClient';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type EditHeadersType = {
  title: string;
  subTitle: string;
};

export const usePutHeaders = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: editHeaders,
    isLoading: isEditHeadersLoading,
    isError
  } = useMutation(
    ['editedHeaders'],
    (data: EditHeadersType) => axios.put(AdvertisingAPI.putHeaders, data, commonHeaders()),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('AdvertisingSettings')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { editHeaders, isEditHeadersLoading, isError };
};
