import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';

type CurrentServicesEmployeesType = {
  workPlace: 'string';
  worker: 'string';
  accessType: 'string';
  status: 'string';
  currentTopic: 'string';
  workTime: 'string';
};

export const useGetCurrentServicesEmployees = () => {
  const {
    data: currentServicesEmployeesResp,
    isFetching: isLoadingCurrentServicesEmployees,
    isSuccess: isCurrentServicesEmployeesRecieved
  } = useQuery(
    ['currentServicesEmployees'],
    () =>
      axios.get<CurrentServicesEmployeesType[]>(
        AdminAnalyticsAndReportsAPI.getCurrentServicesStatusEmployees,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              workPlace: e.workPlace,
              employer: e.worker,
              role: e.accessType,
              status: e.status,
              currentService: e.currentTopic,
              serviceTimeForCurrentService: e.workTime
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    currentServicesEmployeesResp,
    isLoadingCurrentServicesEmployees,
    isCurrentServicesEmployeesRecieved
  };
};
