import classes from './ButtonsEditTheme.module.scss';
import classNames from 'classnames';
import React, { FC } from 'react';

type ButtonsEditThemeType = {
  handleClickTheme: (e: React.MouseEvent<HTMLButtonElement>) => void;
  theme: string;
};

const colors: string[] = ['blue', 'white', 'black'];

export const ButtonsEditTheme: FC<ButtonsEditThemeType> = ({ handleClickTheme, theme }) => {
  const themeClass: string =
    theme === 'blue'
      ? classes.blueTheme
      : theme === 'white'
        ? classes.whiteTheme
        : classes.blackTheme;

  return (
    <div className={classNames(classes.btnGroup, themeClass)}>
      {colors.map((color, index) => (
        <button
          key={index}
          className={classNames(classes.btn, classes[`${color}BtnBg`])}
          data-color={color}
          onClick={handleClickTheme}>
          A
        </button>
      ))}
    </div>
  );
};
