import { useQuery } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsAPI } from '../../constants/API';
import { commonHeaders } from '../../constants/HeadersAPI';

export type topicItem = {
  id: string;
  name: string;
};
export type AgentType = {
  id: number;
  name: string;
  window: string;
  topics: topicItem[];
};

type AgentsTopicsType = {
  agents: AgentType[];
};

export const useCategoriesTopics = () => {
  return useQuery(
    ['getServices'],
    () => axios.get<AgentsTopicsType>(CategoriesTopicsAPI.getCategoriesTopics, commonHeaders()),
    {
      select: ({ data }) => data
    }
  );
};
