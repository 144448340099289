import React, { FC, useState } from 'react';
import classes from './CurrentServicesStatusPage.module.scss';
import CurrentServicesStatusTableQueue from '../../../components/Admin/Tables/AnalyticsAndReports/CurrentServicesStatusTable/CurrentServicesStatusTableQueue';
import CurrentServicesStatusTableEmployers from '../../../components/Admin/Tables/AnalyticsAndReports/CurrentServicesStatusTable/CurrentServicesStatusTableEmployers';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { useGetCurrentServicesEmployees } from '../../../hooks/Admin/AnalyticsAndReportsTables/CurrentServicesStatusTable/useGetCurrentServicesEmployees';
import { useGetCurrentServicesQueue } from '../../../hooks/Admin/AnalyticsAndReportsTables/CurrentServicesStatusTable/useGetCurrentServicesQueue';

const tabsList = ['Очередь', 'Сотрудники'];
const isActiveButtonCSV = false;

const CurrentServicesStatusPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);

  const { currentServicesEmployeesResp: EmployeesTableData, isLoadingCurrentServicesEmployees } =
    useGetCurrentServicesEmployees();
  const { currentServicesQueueResp: QueueTableData, isLoadingCurrentServicesQueue } =
    useGetCurrentServicesQueue();

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        isActiveButtonCSV={isActiveButtonCSV}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        name="Текущее состояние обслуживания посетителей"
      />
      {'Очередь' === activeTab && (
        <CurrentServicesStatusTableQueue
          QueueTableData={QueueTableData}
          isLoadingData={isLoadingCurrentServicesQueue}
        />
      )}
      {'Сотрудники' === activeTab && (
        <CurrentServicesStatusTableEmployers
          EmployeesTableData={EmployeesTableData}
          isLoadingData={isLoadingCurrentServicesEmployees}
        />
      )}
    </div>
  );
};

export default CurrentServicesStatusPage;
