import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

export type topicDataType = {
  nameRu: string;
  nameBe: string;
  nameEn: string;
  itemNamePrefix: string;
  startWork: string;
  endWork: string;
};
export const useEditTopic = (closeEditTopicModal: (value: boolean) => void, topicId: number) => {
  const context = useContext(AlertContext);
  const client: QueryClient = useQueryClient();
  const { mutate: editTopic, isLoading: isLoadingTopicEditing } = useMutation(
    (data: topicDataType) => {
      return axios.put(CategoriesTopicsTableAPI.postEditTopic(topicId), data, commonHeaders());
    },
    {
      onSuccess: () => {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeEditTopicModal(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { editTopic, isLoadingTopicEditing };
};
