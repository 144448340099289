import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

export const useDeleteCategoryById = (closeMenu: (value: boolean) => void) => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: deleteCategory, isLoading: isLoadingCategoryDelete } = useMutation(
    (rowId: number) =>
      axios.delete(CategoriesTopicsTableAPI.deleteCategory(rowId), {
        ...commonHeaders(),
        data: ''
      }),
    {
      onSuccess: () => {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeMenu(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { deleteCategory, isLoadingCategoryDelete };
};
