import { useMutation } from 'react-query';
import { AuthorizationAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { useNavigate } from 'react-router-dom';
import { homeRoutesURLs } from '../../constants/routes';

const data = '';

export const useLogout = (setIsAuthenticated: (status: boolean) => void) => {
  const navigate = useNavigate();
  const {
    mutate: logout,
    isLoading,
    isError: isErrorLogout,
    isSuccess: isSuccessLogout
  } = useMutation(['logout'], () => axios.post(AuthorizationAPI.logOut, data, commonHeaders()), {
    onSuccess: () => {
      localStorage.removeItem('user');
      setIsAuthenticated(false);
      navigate(homeRoutesURLs.loginPagePath);
    }
  });

  return { logout, isLoading, isErrorLogout, isSuccessLogout };
};
