import classNames from 'classnames';
import classes from './ModalTicketInWorking.module.scss';
import React, { FC } from 'react';

type ModalTicketInWorkingProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
};
export const ModalTicketInWorking: FC<ModalTicketInWorkingProps> = ({ isActive, setActive }) => {
  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div
        className={classes.modalContainer}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <div className={classes.modalContent}>
          <p>Этот билет уже взяли в работу выберите другой </p>
        </div>
        <button className={classes.btnUnderstand} onClick={() => setActive(false)}>
          <p>понятно</p>
        </button>
      </div>
    </div>
  );
};
export default ModalTicketInWorking;
