import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { AdminAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AllEmpoyeesDataType } from '../../../pagesAdmin/EmployersPage/EmployersPage';

type AllEmployeesRespType = {
  users: UsersRespType[];
};

type UsersRespType = {
  roleId: number;
  roleDescription: string;
  users?: AssignedUsersType[];
  topics?: AssignedTopicsType[];
};

type AssignedUsersType = {
  id: number;
  login: string;
  isActive: boolean;
  isReadOnly: boolean;
};

type AssignedTopicsType = {
  id: number;
  nameRu: string;
};

export const useGetAllEmployees = (
  setAllEmpoyeesData: (employees: AllEmpoyeesDataType) => void
) => {
  const {
    data: allEmployeesDataResp,
    isFetching: isLoadingAllEmployeesData,
    isSuccess: isAllEmployeesDataRecieved
  } = useQuery('allEmployeesData', () => axios.get(AdminAPI.getAllEmployees, commonHeaders()), {
    onSuccess: (resp: AxiosResponse<AllEmployeesRespType>) => {
      setAllEmpoyeesData({
        employeesTableData: resp?.data.users
          .map((role) => {
            if (role.users?.length) {
              return role.users.map((user) => {
                return {
                  id: user.id,
                  employee: user.login,
                  accessLevel: role.roleDescription,
                  isActive: user.isActive,
                  isReadOnly: user.isReadOnly,
                  roleId: role.roleId
                };
              });
            } else {
              return [];
            }
          })
          .flat(1)
      });
    }
  });

  return { allEmployeesDataResp, isLoadingAllEmployeesData, isAllEmployeesDataRecieved };
};
