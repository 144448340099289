import classes from './ServiceContent.module.scss';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { Button } from '../../../Button/Button';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CustomRadio from '../../../CustomRadio/CustomRadio';
import { TimePickerCustom } from '../../../TimePickerCustom/TimePickerCustom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  rulesByLang,
  rulesEnLang,
  rulesLetterLang,
  rulesRuLang
} from '../../../../../schemas/ModalServiceSchema';
import {
  ModalContentProps,
  ServicesTableType
} from '../../../../../types/Admin/CategoriesTopicsTypes';
import { useCreateTopic } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useCreateTopic';
import { useEditTopic } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useEditTopic';
import { useGetAllCategoriesTopics } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useGetAllCategoriesTopics';
import { ModalMode, TimeMode } from '../../../../../types/enums';

export const ServiceContent: FC<ModalContentProps> = ({
  selectedRadioValue,
  handleRadioChange,
  setActiveModal,
  modalDataFromTable,
  modalMode
}) => {
  const sheduleData =
    modalMode === ModalMode.Edit
      ? modalDataFromTable.startFinishWork.split('-')
      : ['10:00', '18:00'];

  const [startTime, setStartTime] = useState(sheduleData[0]);
  const [endTime, setEndTime] = useState(sheduleData[1]);

  const { allCategoriesTopicsResp, isAllCategoriesTopicsReceived } = useGetAllCategoriesTopics();

  function checkIsNameUnique(nameField: string, value: string): boolean {
    if (isAllCategoriesTopicsReceived && modalMode === ModalMode.Create) {
      return !allCategoriesTopicsResp.services.some(
        (obj: ServicesTableType): boolean => obj[nameField] === value
      );
    }
    return true;
  }

  function checkIsLetterUnique(value: string): boolean {
    if (isAllCategoriesTopicsReceived && !modalDataFromTable.nameRu) {
      return !allCategoriesTopicsResp.services.some(
        (obj: ServicesTableType) => obj.itemNamePrefix.toLowerCase() === value.toLowerCase()
      );
    } else if (modalDataFromTable.nameRu) {
      return !allCategoriesTopicsResp.services.some(
        (obj: ServicesTableType) =>
          obj.itemNamePrefix.toLowerCase() !== modalDataFromTable.itemNamePrefix.toLowerCase() &&
          obj.itemNamePrefix.toLowerCase() === value.toLowerCase()
      );
    }
    return true;
  }

  const ModalServiceSchema = yup.object({
    inputTextRu: yup
      .string()
      .required('Необходимо заполнить поле')
      .matches(rulesRuLang, { message: 'Введите текст на русском языке', excludeEmptyString: true })
      .test('unique-nameRu', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameRu', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .max(43, 'Превышен лимит символов'),
    inputTextEn: yup
      .string()
      .required('Необходимо заполнить поле')
      .matches(rulesEnLang, {
        message: 'Введите текст на английском языке',
        excludeEmptyString: true
      })
      .test('unique-nameEn', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameEn', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .max(53, 'Превышен лимит символов'),
    inputTextBy: yup
      .string()
      .required('Необходимо заполнить поле')
      .matches(rulesByLang, {
        message: 'Введите текст на белорусском языке',
        excludeEmptyString: true
      })
      .test('unique-nameBe', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameBe', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .max(43, 'Превышен лимит символов'),
    letter: yup
      .string()
      .required('Необходимо заполнить поле')
      .matches(rulesLetterLang, {
        message: 'Введите букву верно',
        excludeEmptyString: true
      })
      .test('unique-letter', 'Такая буква уже существует', (value) => checkIsLetterUnique(value))
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .max(1, 'Превышен лимит символов')
  });

  type FormData = yup.InferType<typeof ModalServiceSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(ModalServiceSchema),
    mode: 'onChange',
    defaultValues: {
      inputTextRu: '',
      inputTextEn: '',
      inputTextBy: '',
      letter: ''
    }
  });

  const { createTopic, isLoadingCreatedTopic } = useCreateTopic(() => setActiveModal(false));
  const { editTopic, isLoadingTopicEditing } = useEditTopic(setActiveModal, modalDataFromTable.id);

  useEffect(() => {
    if (modalDataFromTable && modalDataFromTable.startFinishWork) {
      setValue('inputTextRu', modalDataFromTable.nameRu);
      setValue('inputTextEn', modalDataFromTable.nameEn);
      setValue('inputTextBy', modalDataFromTable.nameBe);
      setValue('letter', modalDataFromTable.itemNamePrefix);
    } else {
      handleResetForm();
    }
  }, [modalDataFromTable]);

  function handleResetForm() {
    reset();
  }

  function checkStartTime() {
    return startTime === null;
  }

  function checkEndTime() {
    return endTime === null;
  }

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const { inputTextRu, inputTextEn, inputTextBy, letter } = data;
    const topicData = {
      nameRu: inputTextRu,
      nameBe: inputTextBy,
      nameEn: inputTextEn,
      itemNamePrefix: letter,
      startWork: startTime,
      endWork: endTime
    };
    if (startTime && endTime) {
      modalMode === ModalMode.Edit ? editTopic(topicData) : createTopic(topicData);
    }
  };

  return (
    <form className={classes.serviceForm}>
      <h2>Раздел/ услуга</h2>
      <div className={classes.serviceContent}>
        {(isLoadingCreatedTopic || isLoadingTopicEditing) && (
          <div className={classes.load}>
            <div className={classes.loadContent}>
              <p>Cохранение...</p>
            </div>
          </div>
        )}
        <div>
          <div className={classes.firstRow}>
            <div className={classes.radioContainer}>
              <div className={classes.radio}>
                <CustomRadio
                  id={'section'}
                  value={'section'}
                  checked={selectedRadioValue === 'section'}
                  onChange={handleRadioChange}
                  label={'Раздел'}
                  disabled={modalMode === 'edit' && modalDataFromTable.radioType === 'service'}
                />
              </div>
              <div className={classes.radio}>
                <CustomRadio
                  id={'service'}
                  value={'service'}
                  checked={selectedRadioValue === 'service'}
                  onChange={handleRadioChange}
                  label={'Услуга'}
                  disabled={modalMode === 'edit' && modalDataFromTable.radioType === 'section'}
                />
              </div>
            </div>
            <div className={classes.letterContainer}>
              <CustomTextField
                {...register('letter')}
                name="letter"
                value={watch('letter')}
                errorMessage={errors.letter?.message}
                labelName={'Буква при выдаче билета'}></CustomTextField>
            </div>
          </div>
          <div className={classes.nameRu}>
            <CustomTextField
              {...register('inputTextRu')}
              name="inputTextRu"
              value={watch('inputTextRu')}
              labelName={'Наименование RU'}
              errorMessage={errors.inputTextRu?.message}
            />
          </div>
          <div className={classes.nameEn}>
            <CustomTextField
              {...register('inputTextEn')}
              name="inputTextEn"
              value={watch('inputTextEn')}
              labelName={'Наименование EN'}
              errorMessage={errors.inputTextEn?.message}
            />
          </div>
        </div>
        <div className={classes.rightPart}>
          <div className={classes.timeContainer}>
            <div className={classes.startTime}>
              <TimePickerCustom
                label={'Начало работы'}
                time={startTime}
                setTime={setStartTime}
                modalDataFromTable={modalDataFromTable}
                timeType={TimeMode.Start}
              />
              {checkStartTime() && <p className={classes.timeError}>Необходимо заполнить поле</p>}
            </div>
            <div className={classes.endTime}>
              <TimePickerCustom
                label={'Конец работы'}
                time={endTime}
                setTime={setEndTime}
                modalDataFromTable={modalDataFromTable}
                timeType={TimeMode.End}
              />
              {checkEndTime() && <p className={classes.timeError}>Необходимо заполнить поле</p>}
            </div>
          </div>
          <div className={classes.nameBe}>
            <CustomTextField
              {...register('inputTextBy')}
              name="inputTextBy"
              value={watch('inputTextBy')}
              labelName={'Наименование BY'}
              errorMessage={errors.inputTextBy?.message}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button content={'отмена'} btnStyle={'white'} onClick={() => setActiveModal(false)} />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoadingCreatedTopic || isLoadingTopicEditing}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
