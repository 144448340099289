import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import { checkIcon, modifierIcon } from '../../../../../assets/icons';
import classes from './AdvertisingSettingsTable.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { ActionMenu } from '../../../ActionMenu/ActionMenuGeneral/ActionMenu';
import Skeleton from '../../../Skeleton/Skeleton';
import {
  AdvertisingElements,
  ContentType
} from '../../../../../pagesAdmin/AdvertisingPage/AdvertisingPage';
import { handleClickOutside } from '../../../../../utils/Admin/handleClickOutside';

export type AdvertisingSettingsType = {
  id: number;
  elementTitle: string;
  currentData?: ContentType[];
  isActive: boolean;
};

const columnSizes = {
  elementTitleWidth: 551,
  currentDataWidth: 950,
  isActiveWidth: 94
};

const { elementTitleWidth, currentDataWidth, isActiveWidth } = columnSizes;

type AdvertisingSettingsTableProps = {
  setIsModalEditPlaylistActive: (status: boolean) => void;
  setIsModalEditHeadersActive: (status: boolean) => void;
  advertisingSettingsData: AdvertisingSettingsType[] | [];
  isLoadingData: boolean;
};

const AdvertisingSettingsTable: FC<AdvertisingSettingsTableProps> = ({
  setIsModalEditPlaylistActive,
  setIsModalEditHeadersActive,
  advertisingSettingsData,
  isLoadingData
}) => {
  const [activeRow, setActiveRow] = React.useState(null);
  const columnHelper = createColumnHelper<AdvertisingSettingsType>();

  const [openMenuId, setOpenMenuId] = useState(null);

  const menuElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  const handleButtonClickModal = (data: AdvertisingSettingsType) => {
    if (data.elementTitle === AdvertisingElements.Headers) {
      setIsModalEditHeadersActive(true);
    }
    if (data.elementTitle !== AdvertisingElements.Headers) {
      setIsModalEditPlaylistActive(true);
    }
    setOpenMenuId(false);
  };

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const workingPlacesColumns = [
    columnHelper.accessor('elementTitle', {
      header: () => <div className={classes.headerWorkingPlase}>Элемент</div>,
      cell: ({ getValue, row }) => (
        <div className={classes.cellWorkingPlace}>
          <div className={classes.editMenuContainer}>
            <img
              id={'imgMenu'}
              src={modifierIcon}
              alt="modifierService"
              className={classes.modifierImage}
              onClick={() => {
                if (openMenuId === row?.original?.id) {
                  setOpenMenuId(null);
                } else {
                  setOpenMenuId(row?.original?.id);
                }
              }}
            />

            {openMenuId === row?.original?.id &&
              row.original.elementTitle !== AdvertisingElements.TickersPlaylist && (
                <div ref={menuElement} className={classes.actonMenuContainer}>
                  <ActionMenu editOnClick={() => handleButtonClickModal(row?.original)} />
                </div>
              )}
          </div>
          <div className={classes.workingPlace}>{getValue()}</div>
        </div>
      ),
      size: elementTitleWidth
    }),
    columnHelper.accessor('currentData', {
      cell: ({ getValue }) => (
        <div className={classes.cellEmployees}>
          {getValue() &&
            getValue().map((item, index) => (
              <span key={index}>
                {getValue().length > 1 && getValue().length - 1 !== index
                  ? `${item.title}, `
                  : item.title}
              </span>
            ))}
        </div>
      ),
      header: () => <div className={classes.headerEmployees}>Текущие данные</div>,
      size: currentDataWidth
    }),
    columnHelper.accessor('isActive', {
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.cellIsActive}>
            <img src={checkIcon} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      header: () => <div className={classes.contentCenter}>Активный</div>,
      size: isActiveWidth
    })
  ];

  const columns = workingPlacesColumns;

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : advertisingSettingsData || []),
    [isLoadingData, advertisingSettingsData]
  );

  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows?.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleClick(row.id)}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdvertisingSettingsTable;
