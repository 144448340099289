import { adminRoutesURLs } from '../../../constants/routes';

type SidebarNavigationType = {
  name: string;
  url: string;
};

// todo Hiding Analitycs and Reports
const { sectionServices, workplaces, employers, advertising, userArea } = adminRoutesURLs;

// const { sectionServices, workplaces, employers, parkingSlots, userArea } = adminRoutesURLs;

// Переменные роутов для аналитики и отчетов
const { analyticsAndReportsBase, services, employersReports, visitors, currentServicesStatus } =
  adminRoutesURLs.analyticsAndReports;

export const SidebarNavigation: SidebarNavigationType[] = [
  {
    name: 'Категории и услуги',
    url: sectionServices
  },
  {
    name: 'Рабочие места',
    url: workplaces
  },
  {
    name: 'Сотрудники',
    url: employers
  },
  {
    name: 'Аналитика и отчеты',
    url: `${analyticsAndReportsBase}/${currentServicesStatus}`
  },
  {
    name: 'Реклама',
    url: advertising
  },
  {
    name: 'Личный кабинет',
    url: userArea
  }
];
export const AnalyticsAndReportsNavigation: SidebarNavigationType[] = [
  {
    name: 'Текущее состояние обслуживания посетителей',
    url: currentServicesStatus
  },
  {
    name: 'По услугам',
    url: services
  },
  {
    name: 'По сотрудникам',
    url: employersReports
  },
  {
    name: 'По посетителям',
    url: visitors
  }
];
