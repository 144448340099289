import React, { FC, useEffect, useState } from 'react';
import classes from './EmployersReportsPage.module.scss';
import EmployersReportTable from '../../../components/Admin/Tables/AnalyticsAndReports/EmployersReportTable/EmployersReportsTable';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { useGetEmployeesReport } from '../../../hooks/Admin/AnalyticsAndReportsTables/EmployeesReport/useGetEmployeesReport';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { EmployeesHeader } from '../../../types/enums';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';

const tabsList = ['Период'];
const isActiveButtonCSV = true;

const EmployersReportPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };
  const {
    employeesReportResp: employeesReportData,
    refetchEmployeesReport,
    isLoadingEmployeesReport
  } = useGetEmployeesReport(selectedDate);

  const handleSaveAsCSV = () => saveAsCSV(employeesReportData, EmployeesHeader);

  useEffect(() => {
    refetchEmployeesReport();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={handleSaveAsCSV}
        isActiveButtonCSV={isActiveButtonCSV}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="Отчет по сотрудникам"
      />

      <EmployersReportTable
        employeesReportData={employeesReportData}
        isLoadingData={isLoadingEmployeesReport}
      />
    </div>
  );
};

export default EmployersReportPage;
