import React, { FC } from 'react';
import classes from './IconsStyles.module.scss';

export const QueueArrowIcon: FC = () => {
  return (
    <>
      <svg
        className={classes.queueArrowIcon}
        viewBox="0 0 81 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M49.2218 0.713621L80.244 32.621C80.7281 33.1196 81 33.7954 81 34.5C81 35.2046 80.7281 35.8804 80.244 36.379L49.2218 68.2864C48.7317 68.756 48.0836 69.0117 47.4138 68.9996C46.7441 68.9874 46.1051 68.7084 45.6314 68.2212C45.1578 67.734 44.8865 67.0768 44.8747 66.388C44.8628 65.6991 45.1114 65.0324 45.5681 64.5284L72.1739 37.1589L2.58518 37.1589C1.89955 37.1589 1.24199 36.8788 0.75718 36.3802C0.272365 35.8815 -1.47645e-06 35.2052 -1.50726e-06 34.5C-1.53807e-06 33.7948 0.272364 33.1185 0.75718 32.6198C1.24199 32.1212 1.89955 31.8411 2.58518 31.8411L72.1739 31.841L45.5681 4.47161C45.1114 3.96757 44.8628 3.30089 44.8747 2.61204C44.8865 1.92319 45.1578 1.26595 45.6314 0.778791C46.1051 0.291624 46.7441 0.0125636 47.4138 0.000409914C48.0836 -0.0117437 48.7317 0.243948 49.2218 0.713621Z"
          fill="black"
        />
      </svg>
    </>
  );
};
