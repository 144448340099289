import { useQuery } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';

export type AllTickersRespType = {
  tickers: TickerType[];
};

type TickerType = {
  id: number;
  text: string;
  isActive: boolean;
};

export const useGetAdvertisingTickers = () => {
  const {
    data: advertisingTickersResp,
    isFetching: isLoadingAdvertisingTickers,
    isSuccess: isAdvertisingTickersRecieved
  } = useQuery(
    'allAdvertisingTickers',
    () => axios.get<AllTickersRespType>(AdvertisingAPI.getAllTickers, commonHeaders()),
    {
      select: ({ data }) => data
    }
  );

  return { advertisingTickersResp, isLoadingAdvertisingTickers, isAdvertisingTickersRecieved };
};
