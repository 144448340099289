import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertTriangleIcon, eyeClosedIcon, eyeOutlineIcon } from '../../assets/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './LoginPage.module.scss';
import AuthorizationSchema from '../../schemas/AuthorizationSchema';
import { useLogin } from '../../hooks/Authorization/useLogin';
import { getUserPath, isUserRole } from '../../utils/Authorization/authorization';
import classNames from 'classnames';
import { HeaderLogoIcon } from '../../assets/svgComponents/HeaderLogoIcon';

type LoginFormType = {
  username?: string;
  password?: string;
};

type LoginPageProps = {
  setIsAuthenticated: (status: boolean) => void;
  isAuthenticated: boolean;
};

const LoginPage: FC<LoginPageProps> = ({ isAuthenticated, setIsAuthenticated }) => {
  const { login, isLoading, isError } = useLogin(setIsAuthenticated);
  const [typePassword, setTypePassword] = useState('password');
  const autoComplete = 'off';
  const navigate = useNavigate();
  const handleChangePassword = () => {
    typePassword === 'password' ? setTypePassword('text') : setTypePassword('password');
  };

  useEffect(() => {
    if (isAuthenticated) {
      isUserRole() ? navigate(getUserPath()) : setFocus('username');
    }
  }, [localStorage, isAuthenticated]);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors }
  } = useForm<LoginFormType>({
    defaultValues: {
      username: '',
      password: ''
    },
    resolver: yupResolver(AuthorizationSchema),
    mode: 'onChange'
  });

  const onSubmit: SubmitHandler<LoginFormType> = (data) => {
    login({ login: data.username, password: data.password });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <HeaderLogoIcon isLarge />
      </div>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)} className="login-form ">
          <div className={styles.title}>Вход в систему</div>
          <div className={styles.inputsWrapper}>
            <input
              {...register('username')}
              placeholder="ID пользователя"
              name="username"
              id="username"
              autoComplete={autoComplete}
              className={classNames(styles.formInput, {
                [styles.errorBorder]: errors.username
              })}
            />

            {errors.username && (
              <div className={styles.error}>
                <img src={alertTriangleIcon} alt="iconInvalidPassword" />
                <span className={styles.errorText}>{errors.username.message}</span>
              </div>
            )}

            <div className={styles.passwordWrapper}>
              <input
                {...register('password')}
                type={typePassword}
                placeholder="пароль"
                name="password"
                id="password"
                autoComplete={autoComplete}
                className={classNames(styles.formInput, {
                  [styles.errorBorder]: errors.password
                })}
              />
              <div className={styles.passwordShowIcon}>
                <img
                  src={typePassword === 'password' ? eyeClosedIcon : eyeOutlineIcon}
                  alt="icon-pass"
                  onClick={handleChangePassword}
                />
              </div>
            </div>

            {errors.password && (
              <div className={styles.error}>
                <img src={alertTriangleIcon} alt="iconInvalidPassword" />
                <span className={styles.errorText}>{errors.password.message}</span>
              </div>
            )}
          </div>

          <button type="submit" className={styles.loginBtn}>
            {isLoading ? 'Вход...' : 'Вход'}
          </button>

          {isError && (
            <div className={styles.authorizationFailMessage}>{'Проверьте правильность ввода'}</div>
          )}
        </form>
        <div className={styles.footerWrapper}>
          <span className={styles.footerTitle}>TEHNO SKY Group</span>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
