import Tabs from '../Tabs';
import CustomButton from '../CustomButton';
import css from './ContentHeader.module.scss';
import React, { FC } from 'react';
import { TableType } from '../../../types/Admin/CategoriesTopicsTypes';
import { ModalMode } from '../../../types/enums';

export type ContentHeaderType = {
  name: string;
  tabs?: { data: string; tabText: string }[];
  handleButtonClick?: () => void;
  handleClickTabs?: (activeTable: TableType) => void;
  setModalMode?: (mode: string) => void;
  setModalVideoDownloadActive?: (status: boolean) => void;
  activatedTab?: string;
};

const ContentHeader: FC<ContentHeaderType> = ({
  name,
  handleButtonClick,
  handleClickTabs,
  setModalMode,
  setModalVideoDownloadActive,
  activatedTab,
  tabs
}) => {
  return (
    <div className={css.contentHeader}>
      <div className={css.headerGroup}>
        <div className={css.title}>{name}</div>
        <div>
          {handleClickTabs && (
            <Tabs onHandleClick={handleClickTabs} tabs={tabs} activatedTab={activatedTab} />
          )}
        </div>
      </div>
      {
        <div className={css.btnContainer}>
          {handleButtonClick && (
            <CustomButton
              content={'создать'}
              styleType={'active'}
              onClick={() => {
                if (setModalMode) {
                  setModalMode(ModalMode.Create);
                }
                handleButtonClick();
              }}
            />
          )}
          {setModalVideoDownloadActive && (
            <CustomButton
              content={'Загрузить видео'}
              styleType={'active'}
              onClick={() => setModalVideoDownloadActive(true)}
            />
          )}
        </div>
      }
    </div>
  );
};

export default ContentHeader;
