import * as React from 'react';
import classes from './MenuButton.module.scss';
import classNames from 'classnames';

type MenuButtonProps = {
  content: string;
  isActive: boolean;
  onClick?: () => void;
};

const MenuButton = ({ content, isActive, onClick }: MenuButtonProps) => {
  return (
    <button
      className={classNames(classes.menuButton, {
        [classes.active]: isActive
      })}
      onClick={onClick}>
      <h3
        className={classNames(classes.menuButtonText, {
          [classes.active]: isActive
        })}>
        {content}
      </h3>
    </button>
  );
};
export default MenuButton;
