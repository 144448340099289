import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './EmployersReportsTable.module.scss';
import { FC } from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

type EmployersReportTableType = {
  employer: string;
  calledFromQueue: string;
  served: string;
  redirected: string;
  missed: string;
  averageServiceTime: string;
  averageUnActiveTime: string;
};

const columnSizes = {
  employerSize: 220,
  calledFromQueueSize: 200,
  servedSize: 200,
  redirectedSize: 200,
  missedSize: 200,
  averageServiceTimeSize: 200,
  averageUnActiveTimeSize: 500,
  averageUnActiveTimeMinSize: 50
};

const {
  employerSize,
  calledFromQueueSize,
  servedSize,
  redirectedSize,
  missedSize,
  averageServiceTimeSize,
  averageUnActiveTimeSize,
  averageUnActiveTimeMinSize
} = columnSizes;

type EmployersReportsTableProps = {
  employeesReportData: EmployersReportTableType[];
  isLoadingData: boolean;
};

const EmployersReportTable: FC<EmployersReportsTableProps> = ({
  employeesReportData,
  isLoadingData
}) => {
  const columnHelper = createColumnHelper<EmployersReportTableType>();

  const categoriesColumns = [
    columnHelper.accessor('employer', {
      header: () => <div className={classes.headerContent}>Сотрудник</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: employerSize
    }),
    columnHelper.accessor('calledFromQueue', {
      header: () => <div className={classes.headerContent}>Вызвано из очереди</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: calledFromQueueSize
    }),
    columnHelper.accessor('served', {
      header: () => <div className={classes.headerContent}>Обслужено</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: servedSize
    }),
    columnHelper.accessor('redirected', {
      header: () => <div className={classes.headerContent}>Перенаправлено</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: redirectedSize
    }),
    columnHelper.accessor('missed', {
      header: () => <div className={classes.headerContent}>Не пришли</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: missedSize
    }),
    columnHelper.accessor('averageServiceTime', {
      header: () => <div className={classes.headerContent}>Сред. время обслуживания</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: averageServiceTimeSize
    }),
    columnHelper.accessor('averageUnActiveTime', {
      header: () => <div className={classes.headerContentRight}>Сред. время простоя</div>,
      cell: ({ getValue }) => <div className={classes.columnContentRight}>{getValue()}</div>,
      size: averageUnActiveTimeSize, //starting column size
      minSize: averageUnActiveTimeMinSize //enforced during column resizing
    })
  ];

  const columns = categoriesColumns;
  const [activeRow, setActiveRow] = React.useState(null);

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : employeesReportData || []),
    [isLoadingData, employeesReportData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}>
                    <div className={classes.thTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                        }}>
                        <div className={classes.colorResizer} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleClick(row.id)}
              className={activeRow === row.id ? classes.activeRow : ''}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployersReportTable;
