import { useQuery } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { ContentType } from '../../../pagesAdmin/AdvertisingPage/AdvertisingPage';

type AllAdvertisingVideosType = {
  video: VideoType[];
};

export type VideoType = {
  id: number;
  fileName: string;
  isActive: boolean;
};

export const useGetAllAdvertisingVideos = (setAllSystemVideos: (data: ContentType[]) => void) => {
  const {
    data: advertisingVideosResp,
    isFetching: isLoadingAdvertisingVideos,
    isSuccess: isAdvertisingVideosRecieved
  } = useQuery(
    'allAdvertisingVideos',
    () => axios.get<AllAdvertisingVideosType>(AdvertisingAPI.getAllVideos, commonHeaders()),
    {
      onSuccess: (resp) => {
        setAllSystemVideos(resp.data.video.map((e) => ({ id: e.id, title: e.fileName })));
      }
    }
  );

  return { advertisingVideosResp, isLoadingAdvertisingVideos, isAdvertisingVideosRecieved };
};
