import classes from './ModalAccompaniment.module.scss';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from '../../../Button/Button';

type ModalAccompanimentProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
};
export const ModalAccompaniment: FC<ModalAccompanimentProps> = ({ isActive, setActive }) => {
  return (
    <div
      className={classNames(classes.modal, { [classes.active]: isActive })}
      onClick={() => setActive(false)}>
      <div
        className={classes.modalAccompaniment}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <form className={classes.accompanimentForm}>
          <h2>Сопровождение</h2>
          <div className={classes.textContainer}>
            <span>
              <ul className={classes.servicesP}>
                Включает в себя услуги:
                <li>1. Консультация по телефону горячей линии (2 часа в месяц); </li>
                <li>
                  2. Сокращенное время реагирования (4 рабочих часа на реакцию, время работы
                  9:30-17:30 в будние дни);
                </li>
                <li>
                  <ul className={classes.ulList}>
                    3.Работы по заполнению контентом, восстановлению работоспособности и диагностики
                    (2 часа в месяц):
                    <li>Удаленная диагностика работоспособности программного обеспечения;</li>
                    <li>Резервное копирование программного обеспечения (1 раз в месяц);</li>
                    <li>Восстановление программного обеспечения из резервной копии;</li>
                  </ul>
                </li>
                <li>4. Поддержание работоспособности баз данных; </li>
                <li>
                  5. Полная проверка программного обеспечения на наличие ошибок при обращении, на
                  некорректность работы;
                </li>
                <li>6.Исправление программных ошибок; </li>
                <li>7. Обновление программного обеспечения; </li>
                <li>
                  8. Удаленное обучение новых сотрудников по работе с программным обеспечением 2
                  раза в год.
                </li>
                <p className={classes.informationP}>
                  Подробную информацию можно получить по тел. +375(29) 118 77 72
                </p>
              </ul>
            </span>
          </div>
          <div className={classes.btnContainer}>
            <Button content={'закрыть'} btnStyle={'blue'} onClick={() => setActive(false)} />
          </div>
        </form>
      </div>
    </div>
  );
};
