import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AuthorizationAPI } from '../../constants/API';

type WorkplacesListType = {
  serviceUnitId: number;
  serviceUnitName: string;
  isAvailable: boolean;
};

export const useGetWorkplacesList = () => {
  const {
    data: userWorkplacesResp,
    isFetching: isLoadingUserWorkplaces,
    isSuccess: isUserWorkplacesRespRecived
  } = useQuery(
    ['userWorkplaces'],
    () => axios.get<WorkplacesListType[]>(AuthorizationAPI.getUserWorkplaces, commonHeaders()),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => ({
            label: e.serviceUnitName,
            value: e.serviceUnitId,
            locked: !e.isAvailable
          }));
        }
      }
    }
  );
  return { userWorkplacesResp, isLoadingUserWorkplaces, isUserWorkplacesRespRecived };
};
