import classNames from 'classnames';
import classes from './ModalDelete.module.scss';
import React, { FC } from 'react';
import { modalsSectionServiceDataType } from '../../../../types/Admin/CategoriesTopicsTypes';
import { useDeleteCategoryById } from '../../../../hooks/Admin/CategoriesAndServisesTable/useDeleteCategoryById';
import { useDeleteTopicById } from '../../../../hooks/Admin/CategoriesAndServisesTable/useDeleteTopicById';
import { SectionOrCategory } from '../../../../types/enums';
import { Button } from '../../Button/Button';

type ModalDeleteProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  activeRowData?: modalsSectionServiceDataType;
};

export const ModalDelete: FC<ModalDeleteProps> = ({ isActive, setActive, activeRowData }) => {
  const { deleteCategory, isLoadingCategoryDelete } = useDeleteCategoryById(setActive);
  const { deleteTopic, isLoadingTopicDelete } = useDeleteTopicById(setActive);
  const handleDeleteCategoryTopic = () => {
    if (activeRowData.radioType) {
      if (activeRowData.radioType === SectionOrCategory.Section) {
        deleteCategory(activeRowData.id);
      } else {
        deleteTopic(activeRowData.id);
      }
    }
  };

  return (
    <div
      className={classNames(classes.modal, { [classes.active]: isActive })}
      onClick={() => setActive(false)}>
      <div
        className={classes.modalDelete}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        {(isLoadingCategoryDelete || isLoadingTopicDelete) && (
          <div className={classes.load}>
            <div className={classes.loadContent}>
              <p>Удаление...</p>
            </div>
          </div>
        )}
        <p>Удалить?</p>
        <div className={classes.buttonsContainer}>
          <button
            className={classNames(classes.normal, classes.whiteBtnNo)}
            onClick={() => setActive(false)}>
            {'НЕТ'}
          </button>

          <Button
            content={'ДА'}
            btnStyle={'blue'}
            isDeleteBtn={true}
            onClick={handleDeleteCategoryTopic}
            isLoading={isLoadingCategoryDelete || isLoadingTopicDelete}
          />
        </div>
      </div>
    </div>
  );
};
