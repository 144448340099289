import React, { FC, useState, useEffect } from 'react';

import { convertTime } from '../../../utils/Agents/convertTime';
import { AgentStatus } from '../../../types/enums';

type TimerProps = {
  agentStatus: string;
  className: string;
  timeToRequest: string | null;
};

const Timer: FC<TimerProps> = ({ agentStatus, className, timeToRequest }) => {
  const [time, setTime] = useState(timeToRequest ? Date.now() - Date.parse(timeToRequest) : 0);
  const [activeTimer, setActiveTimer] = useState(false);
  useEffect(() => {
    let intervalId;
    if (activeTimer) {
      intervalId = setInterval(() => setTime(time + 1000), 1000);
    }
    return () => clearInterval(intervalId);
  }, [activeTimer, time]);

  useEffect(() => {
    if (agentStatus === AgentStatus.Waiting) {
      setActiveTimer(false);
      setTime(0);
    }
    if (agentStatus === AgentStatus.Working) {
      setActiveTimer(true);
    }
  }, [agentStatus]);

  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  return <div className={className}>{convertTime(minutes, seconds)}</div>;
};

export default Timer;
