import { useQuery } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { ContentType, HeadersType } from '../../../pagesAdmin/AdvertisingPage/AdvertisingPage';

type SettingsRespType = {
  content: SettingsType[];
};

type SettingsType = {
  contentId: number;
  type: string;
  isActive: boolean;
  info: {
    text?: string;
    video?: VideoFileType[];
    title?: string;
    subTitle?: string;
  };
};

type VideoFileType = {
  order: number;
  fileId: number;
  fileName: string;
};

export const useGetAdvertisingSettings = (
  setPlaylistData: (data: ContentType[]) => void,
  setTickersData: (data: ContentType[]) => void,
  setHeadersData: (data: HeadersType) => void
) => {
  const {
    data: advertisingSettingsResp,
    isFetching: isLoadingAdvertisingSettings,
    isSuccess: isAdvertisingSettingsRecieved
  } = useQuery(
    'AdvertisingSettings',
    () => axios.get<SettingsRespType>(AdvertisingAPI.getAllSettings, commonHeaders()),
    {
      onSuccess: (resp) => {
        setPlaylistData(
          resp.data.content.find((e) => e.type === 'Плейлист')
            ? resp.data.content
                .map(
                  (e) =>
                    e.type === 'Плейлист' &&
                    e.info.video.map((vid) => ({ id: vid.fileId, title: vid.fileName }))
                )
                .flat(1)
                .filter((e) => !!e)
            : []
        );
        setTickersData(
          resp.data.content.find((e) => e.type === 'Бегущая строка')
            ? resp.data.content
                .map((e) => e.type === 'Бегущая строка' && { id: e.contentId, title: e.info.text })
                .filter((e) => !!e)
            : []
        );

        const headersData = resp.data.content.find((e) => e.type === 'Текстовая информация');
        setHeadersData(
          headersData
            ? { title: headersData.info.title, subTitle: headersData.info.subTitle }
            : { title: '', subTitle: '' }
        );
      }
    }
  );

  return { advertisingSettingsResp, isLoadingAdvertisingSettings, isAdvertisingSettingsRecieved };
};
