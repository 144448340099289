import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { WorkingPlacesTableAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type WorkingPlaceUpdateType = {
  workingPlaceId: number;
  workingPlaceStatus: string;
};

export const usePutWorkingPlaceStatus = () => {
  const context = useContext(AlertContext);
  const { mutate: updateWorkingPalceStatus, isLoading: isWorkingPlaceStatusLoading } = useMutation(
    'updateWorkingPlaceStatus',
    (data: WorkingPlaceUpdateType) =>
      axios.put(
        WorkingPlacesTableAPI.putWorkingPlaceStatus(data.workingPlaceId),
        { state: data.workingPlaceStatus },
        commonHeaders()
      ),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('workingPlaces')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { updateWorkingPalceStatus, isWorkingPlaceStatusLoading };
};
