import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { WorkingPlacesTableAPI } from '../../../constants/API';
import { modifyWorkingPlacesData } from '../../../utils/Admin/WorkingPlacesTable/modifyWorkingPlacesData';

export const useGetAllWorkingPlaces = () => {
  const {
    data: workingPlacesResp,
    isFetching: isLoadingWorkingPlaces,
    isSuccess: isWorkingPlacesRecieved
  } = useQuery(
    ['workingPlaces'],
    () => axios.get(WorkingPlacesTableAPI.getAllWorkingPlaces, commonHeaders()),
    {
      select: ({ data: { workPlaces } }) => modifyWorkingPlacesData(workPlaces)
    }
  );

  return { workingPlacesResp, isLoadingWorkingPlaces, isWorkingPlacesRecieved };
};
