import React, { useState } from 'react';
import css from './UserArea.module.scss';
import StatusCard from './StatusCard/StatusCard';
import { infoIcon } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import { ModalAccompaniment } from '../Modals/ModalsPersonalArea/ModalAccompaniment/ModalAccompaniment';
import { ModalLicence } from '../Modals/ModalsPersonalArea/ModalLicense/ModalLicense';
import { linkToService } from '../../../constants/links';

type UserAreaProps = {
  data: {
    company: string;
    location: string;
    license: number;
    licenseStatus: boolean;
    licenseExpirationDate: string;
    pilotStatus: boolean;
    pilotStatusExpirationDate: string;
  };
};

const UserArea = ({ data }: UserAreaProps) => {
  const [modalAccompanimentActive, setModalAccompanimentActive] = useState(false);
  const [modalLicenceActive, setModalLicenseActive] = useState(false);
  const {
    company,
    location,
    license,
    licenseStatus,
    licenseExpirationDate,
    pilotStatus,
    pilotStatusExpirationDate
  } = data;
  const handleButtonClickAccompaniment = () => {
    setModalAccompanimentActive(true);
  };
  const handleButtonClickLicence = () => {
    setModalLicenseActive(true);
  };
  return (
    <div className={css.userAreaWrapper}>
      <ModalAccompaniment
        isActive={modalAccompanimentActive}
        setActive={setModalAccompanimentActive}
      />
      <ModalLicence isActive={modalLicenceActive} setActive={setModalLicenseActive} />
      <div className={css.title}>Личный кабинет</div>
      <div className={css.categoryWrapper}>
        <div className={css.category}>
          <span>Название организации:</span>
          <span>{company}</span>
        </div>
        <div className={css.category}>
          <span>Название объекта:</span>
          <span>{location}</span>
        </div>
      </div>

      <div className={css.subTitleWrapper}>
        <div className={css.subTitle}>Лицензия</div>
        <img
          src={infoIcon}
          className={css.infoIcon}
          alt="infoIcon"
          onClick={handleButtonClickLicence}
        />
      </div>

      <div className={css.categoryWrapper}>
        <div className={css.category}>
          <span>Тип лицензии:</span>
          <span>№{license}</span>
        </div>
        <StatusCard status={licenseStatus} content={'Статус'} />
        <div className={css.category}>
          <span>Срок окончания:</span>
          <span>{licenseExpirationDate}</span>
        </div>
      </div>

      <div className={css.subTitleWrapper}>
        <div className={css.subTitle}>Сопровождение</div>
        <img
          src={infoIcon}
          className={css.infoIcon}
          alt="infoIcon"
          onClick={handleButtonClickAccompaniment}
        />
      </div>

      <div className={css.categoryWrapper}>
        <div className={css.category}>
          <span>Статус услуги сопровождения:</span>
          <StatusCard status={pilotStatus} content={'Статус услуги сопровождения'} />
        </div>
        <div className={css.category}>
          <span>Срок окончания:</span>
          <span>{pilotStatusExpirationDate}</span>
        </div>
      </div>

      <Link className={css.linkToService} to={linkToService}>
        ЗАЯВКА НА СЕРВИСНОЕ ОБСЛУЖИВАНИЕ
      </Link>
    </div>
  );
};

export default UserArea;
