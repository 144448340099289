import { useMutation } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { queryClient } from '../../../queryClient';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type EditTickerType = {
  tickerId: number;
  body: TickerType;
};

type TickerType = {
  text: string;
};

export const usePutTicker = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: editTicker,
    isLoading: isEditTickerLoading,
    isError
  } = useMutation(
    ['editedTicker'],
    (data: EditTickerType) =>
      axios.put(AdvertisingAPI.putTicker(data.tickerId), data.body, commonHeaders()),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('allAdvertisingTickers')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { editTicker, isEditTickerLoading, isError };
};
