import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI, StaticTokens } from '../../constants/API';
import {
  MonitorsUnitType,
  ReceivedSoundArrType
} from '../../components/Main/QueuePanel/QueuePanel';
import { transformReceivedSounds } from '../../utils/QueuePage/transformReceivedSounds';
import { queueScreenHeaders } from '../../constants/HeadersAPI';

export const useQueueItems = (
  setReceivedSoundArr: (soundsArr: ReceivedSoundArrType) => void,
  isSoundPlaying: boolean
) => {
  const isTokenAvailable = !!StaticTokens.monitorToken;
  const {
    data: queueItemsResp,
    isLoading: isLoadingQueueItems,
    isSuccess: isQueueItemsRecieved
  } = useQuery(
    ['windows'],
    () => axios.get<MonitorsUnitType>(MonitorQueueAPI.getQueueItems, queueScreenHeaders()),
    {
      select: ({ data }) => data,
      onSuccess: (data) => setReceivedSoundArr(transformReceivedSounds(data.items)),
      enabled: isTokenAvailable && !isSoundPlaying,
      refetchInterval: 4000
    }
  );

  return { queueItemsResp, isLoadingQueueItems, isQueueItemsRecieved };
};
