import React, { FC, useEffect } from 'react';
import classes from './Alert.module.scss';
import { crossError, crossSuccessIcon, errorIcon, successIcon } from '../../assets/icons';
import { AlertType } from '../../types/enums';

type AlertProps = {
  message: string;
  autoHideDuration?: number;
  onClose?: () => void;
  type?: string;
};

export const Alert: FC<AlertProps> = ({ message, onClose, autoHideDuration, type }) => {
  useEffect(() => {
    if (autoHideDuration && onClose) {
      const timer = setTimeout(onClose, autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [autoHideDuration, onClose]);

  const img = {
    success: { cross: crossSuccessIcon, icon: successIcon },
    error: { cross: crossError, icon: errorIcon }
  };

  return (
    <div
      className={`${classes.errorAlertContainer}  ${type === AlertType.Success ? classes.successAlert : classes.errorAlert}`}>
      <div>
        <img src={img[type].icon} className={classes.errorIcon} alt="errorIcon" />
        <span className={classes.errorText}>{message}</span>
      </div>
      <div className={classes.crossIcon} onClick={() => onClose()}>
        <img src={img[type].cross} className={classes.crossError} alt="errorCross" />
      </div>
    </div>
  );
};

export default Alert;
