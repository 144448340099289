import React from 'react';
import css from './StatusCard.module.scss';

type StatusCardProps = {
  status: boolean;
  content: string;
};

const StatusCard = ({ status, content }: StatusCardProps) => {
  return (
    <div className={css.category}>
      <span>{content}:</span>
      {status && <span className={css.statusTrue}>активна</span>}
      {!status && <span className={css.statusFalse}>не активна</span>}
    </div>
  );
};

export default StatusCard;
