import React, { useEffect, useRef, useState } from 'react';
import classes from './TimePickerCustom.module.scss';
import { ReactComponent as TimeIcon } from '../../../assets/icons/clockIcon.svg';
import classNames from 'classnames';
import { modalsSectionServiceDataType } from '../../../types/Admin/CategoriesTopicsTypes';
import { TimeMode } from '../../../types/enums';

type TimePickerCustomProps = {
  label: string;
  time: string;
  timeType: TimeMode.Start | TimeMode.End;
  setTime: (newValue: string) => void;
  modalDataFromTable: modalsSectionServiceDataType;
};
export const TimePickerCustom = ({
  label,
  time,
  setTime,
  timeType,
  modalDataFromTable
}: TimePickerCustomProps) => {
  const [selectedTime, setSelectedTime] = useState(time);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const timeComponent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalDataFromTable && modalDataFromTable.startFinishWork) {
      const timeArr = modalDataFromTable.startFinishWork.split('-');
      setSelectedTime(timeType === TimeMode.Start ? timeArr[0] : timeArr[1]);
    } else {
      setSelectedTime(timeType === TimeMode.Start ? '10:00' : '18:00');
    }
  }, [modalDataFromTable, modalDataFromTable.id]);

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
    setTime(time);
    setIsMenuOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const timeElement = timeComponent.current;
    if (timeElement && !timeElement.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={timeComponent}>
      <label className={classes.customLabel}>{label}</label>
      <div className={classNames(classes.timePickerContainer, { [classes.active]: isMenuOpen })}>
        <div className={classes.selectedTime} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span>{selectedTime}</span>
          <TimeIcon className={classes.clockIcon} />
        </div>
        {isMenuOpen && (
          <div className={classes.timeOptions}>
            {generateTimeOptions().map((time) => (
              <div key={time} className={classes.optionTime} onClick={() => handleTimeChange(time)}>
                <span>{time}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
