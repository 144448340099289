import classNames from 'classnames';
import classes from './ModalDeleteUse.module.scss';
import React, { FC } from 'react';
import { Button } from '../../Button/Button';

type ModalDeleteUseProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  deleteItem: (id: number) => void;
  id: number;
  isDeleteLoading: boolean;
};

export const ModalDeleteUse: FC<ModalDeleteUseProps> = ({
  isActive,
  setActive,
  id,
  deleteItem,
  isDeleteLoading
}) => {
  return (
    <div
      className={classNames(classes.modal, { [classes.active]: isActive })}
      onClick={() => setActive(false)}>
      <div
        className={classes.modalDelete}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        {isDeleteLoading && (
          <div className={classes.load}>
            <div className={classes.loadContent}>
              <p>Удаление...</p>
            </div>
          </div>
        )}
        <p>Удалить?</p>
        <div className={classes.buttonsContainer}>
          <button
            className={classNames(classes.normal, classes.whiteBtnNo)}
            onClick={() => setActive(false)}>
            {'НЕТ'}
          </button>

          <Button
            content={'ДА'}
            btnStyle={'blue'}
            isDeleteBtn={true}
            onClick={() => deleteItem(id)}
            isLoading={isDeleteLoading}
          />
        </div>
      </div>
    </div>
  );
};
