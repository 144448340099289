import * as React from 'react';
import classes from './CustomTextField.module.scss';
import classNames from 'classnames';
import { LegacyRef, TextareaHTMLAttributes } from 'react';

// eslint-disable-next-line react/display-name
export const CustomTextField = React.forwardRef(
  (
    {
      labelName,
      errorMessage,
      children,
      isTextAreaDisabled,
      isTextarea,
      ...rest
    }: TextareaHTMLAttributes<HTMLTextAreaElement | HTMLInputElement> & {
      labelName?: string;
      errorMessage?: string;
      isTextAreaDisabled?: boolean;
      isTextarea?: boolean;
      children?: React.ReactNode[] | React.ReactNode;
    },
    ref: LegacyRef<HTMLTextAreaElement> | LegacyRef<HTMLInputElement>
  ) => {
    return (
      <div className={classes.customTextFieldContainer}>
        <label className={classes.customLabel}>{labelName}</label>
        {!children && (
          <div className={classes.textareaContainer}>
            {isTextarea ? (
              <textarea
                readOnly={isTextAreaDisabled}
                spellCheck="false"
                className={classNames(classes.customTextfield, { [classes.error]: errorMessage })}
                {...rest}
                ref={ref as LegacyRef<HTMLTextAreaElement>}
              />
            ) : (
              <input
                readOnly={isTextAreaDisabled}
                className={classNames(classes.customTextfield, { [classes.error]: errorMessage })}
                {...rest}
                ref={ref as LegacyRef<HTMLInputElement>}
              />
            )}
            {errorMessage && <h3 className={classes.errorText}>{errorMessage}</h3>}
          </div>
        )}
        {children && (
          <div className={classNames(classes.customTextfield, classes.second)}>{children}</div>
        )}
      </div>
    );
  }
);
