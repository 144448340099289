/* eslint-disable no-useless-escape */
export const mail: string = 'info@high-tech.by';
export const webSite: string = 'high-tech.by';

export const regexBy: RegExp = /^[ёа-яЁА-ЯІіЎў\s.,/-]{5,}$/;
export const regexEn: RegExp = /^[A-Za-z\s.,/-]{5,}$/;
export const regexRu: RegExp = /^[А-Яа-яЁё\s,\.\-\/]+$/;
export const regexOperatorsFullName: RegExp = /^[\w\sа-яА-ЯёЁІЎіўҐґЁё-]*$/;
export const regexOperatorsPhone: RegExp = /^\d+$/;
export const regexDigitsOnly: RegExp = /^\d+$/;
export const regexWorkplaceName = /^[А-Яа-я0-9\s\-,./]+$/u;
export const regexNum = /^\d+$/;
export const regexIP = /^\d+(\.\d+)?$/;
