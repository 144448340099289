import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';
import { queueScreenHeaders } from '../../constants/HeadersAPI';

export type QueueTickersRespType = {
  tickers: TickerType[];
};

type TickerType = {
  id: number;
  text: string;
};

export const useGetQueueTickers = () => {
  const {
    data: queueTickersResp,
    isFetching: isLoadingQueueTickers,
    isSuccess: isQueueTickersRecieved
  } = useQuery(
    'QueueTickers',
    () => axios.get<QueueTickersRespType>(MonitorQueueAPI.getQueueTickers, queueScreenHeaders()),
    {
      select: ({ data }) => data,
      refetchInterval: 30000
    }
  );

  return { queueTickersResp, isLoadingQueueTickers, isQueueTickersRecieved };
};
