import React, { FC } from 'react';
import UserArea from '../../components/Admin/UserArea/UserArea';
import UserAreaTestData from '../../components/Admin/UserArea/testData';
import classes from './UserAreaPage.module.scss';

const UserAreaPage: FC = () => {
  return (
    <div className={classes.container}>
      <UserArea data={UserAreaTestData} />
    </div>
  );
};

export default UserAreaPage;
