import * as React from 'react';
import classes from './AdminLayout.module.scss';
import SideBar from '../../components/Admin/SideBar/Sidebar';
import { Header } from '../../components/Admin/Header/Header';
import AdminRoutes from '../../components/Routes/AdminRoutes';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminRoutesURLs } from '../../constants/routes';

type AdminLayoutProps = {
  setIsAuthenticated: (status: boolean) => void;
};

const AdminLayout: FC<AdminLayoutProps> = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(adminRoutesURLs.sectionServices);
  }, []);

  return (
    <div className={classes.appContainer}>
      <SideBar />
      <div className={classes.rightPart}>
        <Header setIsAuthenticated={setIsAuthenticated} licenseDaysLeft={91} />
        <div>
          <AdminRoutes />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
