import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AgentAPI } from '../../constants/API';

export const useGetAgentQueue = (isWorkPlaceSelected: boolean) => {
  const {
    data: agentQueueResp,
    isFetching: isLoadingAgentQueue,
    isError: isErrorAgentQueue,
    isSuccess: isSuccessAgentQueue
  } = useQuery(['agentQueue'], () => axios.get(AgentAPI.getAgentQueue, commonHeaders()), {
    enabled: isWorkPlaceSelected,
    refetchInterval: 4000
  });
  return { agentQueueResp, isLoadingAgentQueue, isErrorAgentQueue, isSuccessAgentQueue };
};
