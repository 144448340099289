import React, { FC, useState } from 'react';
import ContentHeader from '../../components/Admin/ContentHeader';
import AllItemsTable from '../../components/Admin/Tables/CategoriesAndServicesTable/AllItemsTable/AllItemsTable';
import SectionsTable from '../../components/Admin/Tables/CategoriesAndServicesTable/SectionsTable/SectionsTable';
import ServicesTable from '../../components/Admin/Tables/CategoriesAndServicesTable/ServicesTable/ServicesTable';
import { ModalSectionService } from '../../components/Admin/Modals/ModalSectionService/ModalSectionService';
import classes from './SectionServicePage.module.scss';
import { useGetAllCategoriesTopics } from '../../hooks/Admin/CategoriesAndServisesTable/useGetAllCategoriesTopics';
import { SectionOrServiceForTable } from '../../types/Admin/CategoriesTopicsTypes';
import { SectionOrCategory, TableName, TableType } from '../../types/enums';
import { ModalDelete } from '../../components/Admin/Modals/ModalDelete/ModalDelete';

const tableName = 'Категории и услуги';

const tabs = [
  { data: TableType.All, tabText: TableName.All },
  { data: TableType.Sections, tabText: TableName.Sections },
  { data: TableType.Services, tabText: TableName.Services }
];

const tablesMap = {
  all: AllItemsTable,
  sections: SectionsTable,
  services: ServicesTable
};
const SectionServicesPage: FC = () => {
  const [activeTable, setActiveTable] = useState('all');
  const TableComponent = tablesMap[activeTable];
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalDeleteActive, setModalDeleteActive] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState('');

  const [activeRowData, setActiveRowData] = useState<SectionOrServiceForTable>(null);

  const { allCategoriesTopicsResp, isLoadingAllCategoriesTopics } = useGetAllCategoriesTopics();

  const handleActiveModal = (): void => {
    setModalActive(true);
    setActiveRowData(null);
  };

  const checkRadioType =
    activeRowData !== null
      ? activeTable === TableType.All
        ? activeRowData.radioType ?? ''
        : activeTable === TableType.Sections
          ? SectionOrCategory.Section
          : SectionOrCategory.Service
      : null;

  return (
    <div className={classes.container}>
      <ContentHeader
        name={tableName}
        handleClickTabs={setActiveTable}
        handleButtonClick={handleActiveModal}
        tabs={tabs}
        setModalMode={setModalMode}
      />
      {modalActive && (
        <ModalSectionService
          isActive={modalActive}
          setActive={setModalActive}
          activeRowData={{
            ...activeRowData,
            radioType: checkRadioType
          }}
          modalMode={modalMode}
        />
      )}
      {modalDeleteActive && (
        <ModalDelete
          isActive={modalDeleteActive}
          setActive={setModalDeleteActive}
          activeRowData={activeRowData}
        />
      )}

      <TableComponent
        activeRowData={activeRowData}
        setActiveRowData={setActiveRowData}
        setModalActive={setModalActive}
        setModalDeleteActive={setModalDeleteActive}
        tableData={allCategoriesTopicsResp}
        setModalMode={setModalMode}
        isLoadingData={isLoadingAllCategoriesTopics}
      />
    </div>
  );
};

export default SectionServicesPage;
