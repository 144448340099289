import classNames from 'classnames';
import React, { useEffect } from 'react';
import classes from './ModalTableText.module.scss';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { Button } from '../../../Button/Button';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTextSchema } from '../../../../../schemas/InputTextSchema';
import { EditHeadersType } from '../../../../../hooks/Admin/Advertising/usePutHeaders';
import { HeadersType } from '../../../../../pagesAdmin/AdvertisingPage/AdvertisingPage';

type ModalSectionServiceProps = {
  isActive: boolean;
  isLoading: boolean;
  editHeaders: (body: EditHeadersType) => void;
  setActive: (status: boolean) => void;
  data?: HeadersType;
};
export const ModalTableText = ({
  isActive,
  isLoading,
  setActive,
  data,
  editHeaders
}: ModalSectionServiceProps) => {
  type FormData = yup.InferType<typeof InputTextSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(InputTextSchema),
    mode: 'onChange',
    defaultValues: {
      titleText: '',
      subTitleText: ''
    }
  });
  useEffect(() => {
    if (data) {
      setValue('titleText', data.title);
      setValue('subTitleText', data.subTitle);
    }
  }, [data]);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const { titleText, subTitleText } = data;
    editHeaders({ title: titleText, subTitle: subTitleText });
  };
  return (
    <div
      className={classNames(classes.modal, { [classes.active]: isActive })}
      onClick={() => setActive(false)}>
      <div
        className={classes.modalContainer}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <form className={classes.formText}>
          {isLoading && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>Редактирование заголовков</h2>
          <div className={classes.textInput}>
            <CustomTextField
              {...register('titleText')}
              isTextarea={false}
              name="titleText"
              value={watch('titleText')}
              labelName={'Заголовок'}
              errorMessage={errors.titleText?.message}
            />
          </div>
          <div className={classes.textInput}>
            <CustomTextField
              {...register('subTitleText')}
              isTextarea={false}
              name="subTitleText"
              value={watch('subTitleText')}
              labelName={'Подзаголовок'}
              errorMessage={errors.subTitleText?.message}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button content={'отмена'} btnStyle={'white'} onClick={() => setActive(false)} />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
