import React, { FC } from 'react';
import classes from './IconsStyles.module.scss';
import classNames from 'classnames';

type Props = {
  isRegular?: boolean;
  isLarge?: boolean;
};

export const HeaderLogoIcon: FC<Props> = ({ isRegular = false, isLarge = false }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="body_1"
        className={classNames({
          [classes.headerLogoWideIcon]: !isRegular && !isLarge,
          [classes.headerLogoRegularIcon]: isRegular && !isLarge,
          [classes.headerLogoLargeIcon]: isLarge && !isRegular
        })}
        viewBox={'40 30 380 380'}>
        <g transform="matrix(1.3333334 0 0 1.3333334 0 0)">
          <path
            d="M0 0L339.75 0L339.75 339.75L0 339.75z"
            stroke="none"
            fill="none"
            fillRule="evenodd"
          />
          <g transform="matrix(0.75 0 0 0.75 -0 -0)">
            <g>
              <g transform="matrix(1 0 0 1 226.77165 226.77165)">
                <g>
                  <g>
                    <g>
                      <g></g>
                    </g>
                  </g>
                  <g></g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-142.95383 -116.20044C -131.57443 -99.97946 -131.67874 -99.97946 -131.67874 -99.97946C -121.53525 -105.06331 -109.00271 -106.257256 -97.95704 -104.27036C -80.4427 -101.11937 -62.201195 -93.33884 -47.32271 -83.758865C -33.019844 -74.54967 -26.44233 -68.89851 -24.876472 -68.061356C -23.310616 -67.22418 -19.65657 -78.00302 -19.65657 -78.00302C -36.260788 -91.45285 -54.14589 -103.80699 -74.04926 -111.90992C -92.024315 -119.227844 -113.84353 -124.804535 -133.03596 -119.86318C -144.41574 -116.932915 -142.95383 -116.20044 -142.95383 -116.20044z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-92.75263 8.024693C -76.06224 14.70085 -76.06224 14.70085 -76.06224 14.70085C -76.50444 -3.8891344 -65.285675 -8.048504 -50.944256 -0.42066193C -50.617702 -0.24680328 -50.2972 -0.07143307 -49.99219 0.10280275C -47.03698 1.7888498 -44.97638 3.4348345 -44.97638 3.4348345L-44.97638 3.4348345L-42.26419 -8.039812C -50.037167 -16.41071 -67.562454 -23.01241 -78.6429 -20.23597C -88.56416 -17.749418 -92.52057 -8.56819 -92.84145 1.2695427C -92.94576 4.4613543 -92.75263 8.024693 -92.75263 8.024693z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-127.71137 -52.468155C -111.63364 -37.189415 -112.260284 -37.189415 -112.260284 -37.189415C -108.67049 -42.15836 -100.95119 -48.80428 -95.03395 -50.58444C -93.99005 -50.898518 -91.69323 -52.36384 -83.13222 -52.36384C -66.75629 -52.36384 -44.55043 -39.105255 -29.17833 -25.269918C -24.79937 -21.329006 -20.015244 -17.533226 -17.255432 -13.74765L-17.255432 -13.74765L-9.529701 -22.01499C -9.529701 -22.01499 -14.228035 -27.666517 -23.62394 -36.45694C -43.633133 -55.176563 -74.468414 -73.88976 -103.07301 -69.1079C -103.69928 -69.00321 -107.97959 -68.061356 -114.3481 -64.293915C -120.7166 -60.526485 -127.71137 -52.468155 -127.71137 -52.468155z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-85.11572 27.484722L-71.85714 27.38003C -70.50898 31.97329 -65.79818 37.728752 -62.460857 41.193825C -53.503754 50.49373 -40.10117 58.76523 -27.695251 50.717102C -27.695251 50.717102 -27.69563 51.44995 -20.59616 58.46135C -13.496696 65.472755 -13.392387 65.36806 -13.392387 65.36806C -16.821175 68.80516 -18.469421 71.07817 -23.519249 72.065765C -35.92177 74.49109 -46.813614 68.29795 -56.405678 61.1297C -68.42193 52.149918 -76.537704 39.523273 -85.11572 27.484722z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-165.8177 -100.921326L-152.45442 -86.47975C -161.18022 -75.22243 -164.97676 -60.499657 -165.18463 -44.170208C -165.84378 7.587021 -140.97412 52.763718 -106.80416 90.49814C -98.22388 99.97342 -90.10205 109.16182 -80.00013 116.751495L-80.00013 116.751495L-82.40164 129.10034C -82.40164 129.10034 -86.22388 126.576004 -92.155464 121.38066C -134.43932 84.34393 -175.84668 20.491463 -181.50237 -36.14022C -183.83206 -59.471626 -181.20642 -81.798805 -165.8177 -100.921326z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-47.271305 138.00113L-48.523087 151.7707C -48.523087 151.7707 -45.13058 154.21643 -39.80598 156.83263C -33.151745 160.10191 -26.856943 162.76724 -19.865194 165.20465C -17.464062 166.04182 -14.123337 167.29738 -7.2328796 168.8674C 32.61128 177.94583 69.78482 164.05153 78.55257 121.186386C 79.67395 115.70872 80.24693 109.93436 80.44838 105.70506C 80.965805 94.85518 79.21097 89.22859 79.21097 89.22859L79.21097 89.22859L64.28183 81.589035C 64.28183 81.589035 64.81853 88.63823 64.69115 94.718735C 64.35968 110.54248 60.608883 125.534355 50.291904 138.10016C 25.907154 167.80043 -19.212471 153.87024 -47.271305 138.00113z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-135.93903 -25.981985L-118.62274 -15.550489C -118.62274 -15.550489 -119.45726 -13.46419 -118.41411 -6.579403C -117.370964 0.3053856 -116.11919 8.859213 -114.241516 13.031809C -112.363846 17.204409 -112.57248 19.499338 -106.52221 30.765352C -100.47194 42.031364 -103.81001 37.024254 -97.55112 46.621223C -91.29222 56.2182 -84.616066 62.894356 -82.52977 64.98066C -80.44347 67.066956 -75.64497 72.28271 -68.96882 78.33298C -62.292664 84.38324 -58.120064 86.46954 -52.695686 90.43351C -47.27131 94.39748 -32.249954 99.82185 -29.746399 99.82185C -27.242836 99.82185 -6.3798523 106.70664 5.720688 93.77159C 17.821228 80.83653 17.612595 73.951744 15.526291 44.534927L15.526291 44.534927L30.66481 50.194016C 30.66481 50.194016 30.756287 48.498894 33.05121 63.52025C 35.346146 78.5416 30.964905 98.77871 26.375046 103.78582C 21.785202 108.792946 15.383804 125.7978 -24.530647 118.389915C -78.3035 108.41045 -136.96327 29.714643 -135.93903 -25.981985z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M-40.43225 31.879936C -46.383118 32.508095 -43.640316 27.714897 -38.34444 25.182236C -36.006046 24.063873 -31.977072 21.804472 -27.781796 18.990992C -24.620977 16.87181 -21.364914 14.436283 -18.66671 11.938772C -15.860032 9.340345 -10.573985 4.6945515 -7.8394966 1.796032C 11.302675 -18.49663 25.7246 -43.64939 34.302994 -68.75112C 39.935623 -85.232506 39.72737 -99.00246 28.669985 -104.426834C 17.612597 -109.85121 31.39578 -109.77411 33.483215 -109.8172C 36.35074 -109.87654 43.16069 -110.50168 50.50998 -113.65834C 57.98324 -116.86716 62.851273 -120.37758 69.036095 -125.22255C 76.789795 -131.2974 79.35345 -129.87553 70.54601 -115.57266C 63.974174 -104.90079 61.41694 -99.47415 59.148468 -94.50407C 57.78746 -91.52202 56.530014 -88.70325 54.572594 -85.01481C 50.861855 -78.021164 42.7714 -59.77323 34.826454 -40.262177C 24.280817 -14.365229 14.9427395 14.079495 25.749166 24.506456C 37.640694 35.980724 26.375055 31.182613 21.576567 30.139463C -0.4985199 25.3406 -18.792568 29.595968 -40.43225 31.879936z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M84.374176 -86.067406C 84.374176 -86.067406 59.755844 -63.535374 52.871056 -21.600761C 45.98627 20.333855 68.72693 29.30494 88.964035 31.808502C 109.20114 34.312057 135.27988 12.8231735 146.12863 0.9312744C 156.97739 -10.960632 164.48807 -22.85254 168.24341 -29.528698C 171.99875 -36.204853 172.62463 -37.248005 178.25763 -53.521137C 183.89066 -69.79427 185.14243 -83.98111 185.14243 -83.98111C 185.14243 -83.98111 186.18558 -94.412605 183.26476 -105.26136C 180.34393 -116.110115 171.99875 -122.9949 171.79012 -122.78627C 171.58148 -122.57764 168.03477 -128.21065 153.84793 -128.62791C 139.6611 -129.04517 120.46715 -117.77915 119.424 -117.570526C 118.38085 -117.36189 92.30212 -97.75068 84.374176 -86.067406z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M94.80567 -70.83741C 88.33815 -58.31962 88.546776 -57.067837 87.086365 -51.2262C 81.335434 -28.221352 84.88139 -8.91552 113.999626 -14.298706C 144.73663 -19.980843 171.3086 -64.58494 173.25052 -94.203964C 174.2846 -109.97745 168.8179 -119.82425 152.8048 -120.49133C 129.64195 -121.456245 104.40567 -89.417946 94.80567 -70.83741z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#FFFDFC"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M67.70834 -170.37808C 67.70834 -163.18767 64.848915 -156.2844 59.764526 -151.20001C 54.680138 -146.11562 47.776867 -143.2562 40.586456 -143.2562C 33.39604 -143.2562 26.492775 -146.11562 21.408384 -151.20001C 16.323994 -156.2844 13.464566 -163.18767 13.464566 -170.37808C 13.464566 -177.5685 16.323994 -184.47176 21.408384 -189.55615C 26.492775 -194.64055 33.39604 -197.49997 40.586456 -197.49997C 47.776867 -197.49997 54.680138 -194.64055 59.764526 -189.55615C 64.848915 -184.47176 67.70834 -177.5685 67.70834 -170.37808z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#1E412D"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M33.051212 -177.49646L33.051212 -189.13058L33.051212 -189.13058L48.69846 -189.13058L48.69846 -189.13058L48.69846 -177.60681L48.69846 -177.60681L60.22186 -177.68845L60.22186 -177.68845L60.332222 -162.0416L60.332222 -162.0416L48.69846 -161.9592L48.69846 -161.9592L48.69846 -150.32544L48.69846 -150.32544L33.051212 -150.32544L33.051212 -150.32544L32.969955 -161.84808L32.969955 -161.84808L21.527813 -161.76758L21.527813 -161.76758L21.41745 -177.41443L21.41745 -177.41443L33.051212 -177.49646z"
                      stroke="#EAC63C"
                      strokeWidth="1.8897638"
                      fill="#FFFDFC"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
