import React, { FC } from 'react';

import Timer from '../Timer/Timer';
import classes from './AgentTicket.module.scss';
import { AgentsTicketType, TicketCommentsType } from '../../../pagesMain/Agent1Page/Agent1Page';
import { format } from 'date-fns';

type TicketPropsType = {
  agentStatus: string;
  timeToRequest: string | null;
  ticket: AgentsTicketType;
  comments: TicketCommentsType;
};

const AgentTicket: FC<TicketPropsType> = ({ ticket, timeToRequest, agentStatus, comments }) => {
  const { name, dateTime, topicName } = ticket;
  return (
    <div className={classes.container}>
      <div className={classes.ticketName}>Билет {name}</div>
      <div className={classes.timeContainer}>
        <div>
          <div className={classes.titleInfo}>Талон взят</div>
          <span className={classes.textInfo}>{format(dateTime, 'HH:mm')}</span>
        </div>
        <div className={classes.request}>
          <div className={classes.titleInfo}>Вызван</div>
          <span className={classes.textInfo}>
            {timeToRequest && format(timeToRequest, 'HH:mm')}
          </span>
        </div>
      </div>
      <div className={classes.service}>
        <div className={classes.titleInfo}>Услуга</div>
        <span className={classes.textInfo}>{topicName}</span>
      </div>
      <div className={classes.comment}>
        <div className={classes.titleInfo}>Комментарий</div>
        <div className={classes.commentContainer}>
          {comments
            ? comments.map((comment: string, index: number) => (
                <div key={index} className={classes.textInfo}>
                  {comment}
                </div>
              ))
            : null}
        </div>
      </div>
      <div className={classes.titleInfo}>В работе</div>
      <div>
        <Timer
          agentStatus={agentStatus}
          timeToRequest={timeToRequest}
          className={classes.timerInfo}
        />
        <span className={classes.seconds}>сек.</span>
      </div>
    </div>
  );
};

export default AgentTicket;
